import Icon from '@mdi/react'
import { mdiWrench } from '@mdi/js'
import { useHistory } from 'react-router'

export default function GoTestingButton({ ...rest }) {
  const history = useHistory()

  if (process.env.NODE_ENV !== 'development') return null

  const goTesting = () => history.push('/testing')

  return (
    <Icon
      path={mdiWrench}
      size={1}
      onClick={goTesting}
      color={'red'}
      role="button"
      {...rest}
    />
  )
}
