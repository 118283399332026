import { createContext, useState, useEffect, useMemo } from 'react'
import jwtDecode from 'jwt-decode'
import { TOKEN } from '../utils/constants'
import { useHistory } from 'react-router-dom'

const AuthContext = createContext({})

export function AuthContextProvider({ children }) {
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [auth, setAuth] = useState(undefined)

  useEffect(() => {
    const token = localStorage.getItem(TOKEN)
    if (token) {
      setAuth({
        token,
        userId: jwtDecode(token)._id,
        role: jwtDecode(token).role
      })
    } else {
      setAuth(null)
    }

    setLoading(false)
  }, [])

  const login = (token) => {
    const { role, _id } = jwtDecode(token)

    if (role === 'altero') return null

    localStorage.setItem(TOKEN, token)
    setAuth({
      token,
      userId: _id,
      role
    })
  }

  const logout = () => {
    if (auth) {
      history.push('/')
      setAuth(null)
      localStorage.removeItem(TOKEN)
    }
  }

  const authData = useMemo(
    () => ({
      auth,
      loading,
      login,
      logout
    }),
    [auth]
  )

  return (
    <AuthContext.Provider value={authData}>{children}</AuthContext.Provider>
  )
}

export default AuthContext
