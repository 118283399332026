import { useHistory } from 'react-router'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import useAuth from 'hooks/useAuth'
import { BASE_PATH } from 'utils/constants'
import { ButtonByfi, InputByfi } from 'styles-byfi'
import LogoFull from 'components/Branding/LogoFull'
import { BackgroundLoginStyled, CardLoginStyled } from './LoginStyled'
import { toast, ToastContainer } from 'react-toastify'

export default function Login() {
  const { login } = useAuth()
  const history = useHistory()

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true)
        const options = {
          headers: {
            'Content-Type': 'application/json'
          },
          method: 'POST',
          body: JSON.stringify(values)
        }
        const response = await fetch(`${BASE_PATH}/login`, options)
        if (!response.ok) throw await response.text()

        const data = await response.json()
        const loginRes = login(data)

        if (loginRes === null) {
          toast.error('No autorizado')
          history.push('/altas')
        }
      } catch (error) {
        console.error(error)
        toast.error(error)
      } finally {
        setSubmitting(false)
      }
    }
  })

  const { handleChange, handleSubmit, isSubmitting, values, errors } = formik
  const buttonText = isSubmitting ? 'Accediendo...' : 'Acceder'

  return (
    <BackgroundLoginStyled>
      <div className="d-flex min-vh-100 justify-content-center align-items-center">
        <CardLoginStyled>
          <center>
            <LogoFull width="130" color="#4911C1" />
          </center>

          <form onSubmit={handleSubmit}>
            <InputByfi
              label="Usuario"
              type="text"
              name="dni"
              style={{ textAlign: 'center' }}
              errors={errors.dni}
              value={values.dni}
              onChange={handleChange}
            />
            <InputByfi
              label="Contraseña"
              type="password"
              name="password"
              style={{ textAlign: 'center' }}
              errors={errors.dni}
              value={values.password}
              onChange={handleChange}
            />
            <ButtonByfi
              primary
              block
              className="mb-5"
              type="submit"
              disabled={isSubmitting}
            >
              {buttonText}
            </ButtonByfi>
            <hr />
            <ButtonByfi
              success
              block
              type="button"
              className="mt-5"
              onClick={() => history.push('/firma')}
            >
              Ir a firmar
            </ButtonByfi>
          </form>
        </CardLoginStyled>
      </div>
      <ToastContainer autoClose={2500} />
    </BackgroundLoginStyled>
  )
}

function initialValues() {
  return {
    dni: '',
    password: ''
  }
}

function validationSchema() {
  return {
    dni: Yup.string().required('Requerido'),
    password: Yup.string().required('Requerido')
  }
}
