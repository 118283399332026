import { ServicesTableRow } from 'components/Contract/VisualComponents/ContractDataTable'
import { TableResumeStyle } from 'components/Contract/VisualComponents/ContractStyles'
import { euroFormat } from 'utils/prices'

export default function ContractResume({ data }) {
  const hasRate = Boolean(data.rate)
  return <ResumeTable {...{ data, hasRate }} />
}

function ResumeTable({ data, hasRate }) {
  const extraServices = data.contractedServices.filter((s) => s.extra)

  if (!hasRate)
    return (
      <div>
        <ServicesTable
          services={data.contractedServices.filter((s) => !s.extra)}
        />
        <ExtrasTable services={extraServices} />
      </div>
    )

  return (
    <div>
      <RateServicesTable rate={data.rate} />
      <ExtrasTable services={extraServices} />
    </div>
  )
}

function RateServicesTable({ rate }) {
  const displayRateRows = rate.services.map((service) => (
    <ServicesTableRow service={service} rate key={service._id} />
  ))

  return (
    <>
      <h1 className="resumen-tarifa">Así queda tu tarifa mensual: </h1>
      <TableResumeStyle className="table">
        <thead>
          <tr>
            <th>Resumen del producto</th>
            <th>Alta</th>
            <th className="right">Cuota mensual</th>
          </tr>
        </thead>
        <tbody>
          {displayRateRows}
          <tr>
            <td></td>
            <td></td>
            <td className="right">
              <h4>{euroFormat(rate.price)} €</h4>
            </td>
          </tr>
        </tbody>
      </TableResumeStyle>
    </>
  )
}

function ServicesTable({ services }) {
  const displayRateRows = services.map((service) => (
    <ServicesTableRow service={service.service} key={service._id} />
  ))

  return (
    <>
      <div className="title-form d-flex justify-content-between">
        <h1>Tu paquete a medida</h1>
      </div>
      <TableResumeStyle className="table">
        <thead>
          <tr>
            <th>Resumen del producto</th>
            <th>Alta</th>
            <th className="right">Cuota mensual</th>
          </tr>
        </thead>
        <tbody>{displayRateRows}</tbody>
      </TableResumeStyle>
    </>
  )
}

function ExtrasTable({ services }) {
  if (!services || !services.length) return null

  const displayExtras = services.map((s) => (
    <ServicesTableRow service={s.service} key={s.createdAt} isExtra={s.extra} />
  ))

  return (
    <>
      <h2 className="title-form">Extras contratados</h2>
      <TableResumeStyle className="table">
        <thead>
          <tr>
            <th>Resumen del producto</th>
            <th>Alta</th>
            <th className="right">Cuota mensual</th>
          </tr>
        </thead>
        <tbody>{displayExtras}</tbody>
      </TableResumeStyle>
    </>
  )
}
