import { NavElement } from './NavBarElements'

export default function UserIcon({ username, name, logout }) {
  return (
    <div className="user-icon">
      {username}
      <ul className="submenu">
        <div className="submenu-container">
          <h4>¡Hola, {name}!</h4>
          <NavElement to="/perfil" text="Cambiar contraseña" item />
          <NavElement to="/session" text="Cerrar sesión" cb={logout} item />
        </div>
      </ul>
    </div>
  )
}
