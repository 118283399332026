import { Link, useHistory, useLocation } from 'react-router-dom'
import useGlobalData from 'hooks/useGlobalData'
import { devTitle } from 'utils/dev'

import UserIcon from './UserIcon'
import NavBarElements from './NavBarElements'
import GoTestingButton from 'components/Buttons/GoTesting'

import { ButtonContract, NavbarStyled } from './NavbarStyle'
import TopNavbar from './TopNavbar'
import { CALL_AGAIN, INFO_COMMENT } from 'utils/constants'

export default function UserNavbar({ auth, logout }) {
  const history = useHistory()
  const {
    data: { users, clients },
    loading
  } = useGlobalData()
  const location = useLocation()

  const newLeads = clients.filter(
    (client) =>
      client.status === 'lead' &&
      Boolean(client.active) &&
      (client.comments.length === 0 ||
        client.comments[client.comments.length - 1].comment === INFO_COMMENT)
  )

  const clientDemandInfo = clients.filter(
    (client) =>
      client.active &&
      client.status === 'client' &&
      client.comments?.length > 0 &&
      client.comments.find(
        (comment) =>
          comment.type === CALL_AGAIN &&
          !comment.isSolved &&
          comment.comment === INFO_COMMENT
      )
  )

  const handleClick = () => history.push('/contratar')
  if (loading) return null

  const user = users.find((u) => u._id === auth.userId)
  const name = user ? user.name : ''
  const username = user ? `${user.name[0]}${user.lastname[0]}` : ''

  return (
    <>
      <TopNavbar clients={clients} />
      <NavbarStyled>
        <div className="container navbar-content">
          <Link to="/">
            <img src="/images/logo-navbar.svg" className="navbar-logo" />
          </Link>
          <nav>
            {devTitle}
            <Link to="/leadsDistribuidores">Leads de distribuidores</Link>
            <NavBarElements
              role={auth.role}
              {...{ newLeads, clientDemandInfo }}
            />
            {!location.pathname.includes('contratos/nuevo') && (
              <ButtonContract className="ml-4" onClick={handleClick}>
                Contratar
              </ButtonContract>
            )}
            <UserIcon {...{ username, logout, name }} />
            <GoTestingButton className="ml-5" />
          </nav>
        </div>
      </NavbarStyled>
    </>
  )
}
