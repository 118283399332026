import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import App from './App'
import { AuthContextProvider } from './context/AuthContext'

import 'styles-byfi/src/bootstrap.css'
import './bootstrap.css'
import { ThemeByfi } from 'styles-byfi'
import { MainStyles } from 'styles/MainStyles'

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={ThemeByfi}>
      <AuthContextProvider>
        <MainStyles>
          <App />
        </MainStyles>
      </AuthContextProvider>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById('root')
)
