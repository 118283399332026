import { ThemeByfi } from 'styles/ThemeByfi'

export const BASE_PATH =
  process.env.NODE_ENV === 'development'
    ? // ? 'http://localhost:5000/api'
      'http://localhost:8080/api'
    : process.env.REACT_APP_API_URL

export const TOKEN = '@sistelComercialToken'

// EXTCLIENT AUTH
export const EXT_CLIENT_AUTH = process.env.REACT_APP_EXT_CLIENT_AUTH

// PHICUS
export const PHICUS_BASE_PATH = process.env.REACT_APP_PHICUS_BASE
export const PHICUS_USER = process.env.REACT_APP_PHICUS_USER
export const PHICUS_PWD = process.env.REACT_APP_PHICUS_PWD

export const PHICUS_TOKEN = '@phicusToken'

export const PHICUS = {
  PHICUS_BASE_PATH,
  PHICUS_USER,
  PHICUS_PWD,
  PHICUS_TOKEN
}

// OPTION/SERVICE TYPES
export const TV = 'TV'
export const MOBILE = 'MOBILE'
export const LANDLINE_PHONE = 'LANDLINE_PHONE'
export const INTERNET = 'INTERNET'
export const ALARM = 'ALARM'
export const EXTRA = 'EXTRA'

export const OPTION_TYPES = [TV, MOBILE, INTERNET]

// CONTRACT CONDITIONS
export const MAX_MOBILE_LINES = 4

// CONTRACT STATUS
export const PENDING = 'PENDING_SIGNATURE'
export const SIGNED = 'SIGNED'
export const VALID = 'VALID'
export const CANCELLED = 'CANCELLED'
export const CONTRACT_STATUS = [PENDING, SIGNED, VALID]

// USER ROLES
export const USER = 'user'
export const ALTERO = 'altero'
export const RESPONSABLE = 'responsable'
export const ADMIN = 'admin'
export const ROLES = { USER, RESPONSABLE, ADMIN, ALTERO }

// CLIENT TYPES
export const PRIVATE = 'PRIVATE'
export const SELF_EMPLOYED = 'SELF_EMPLOYED'
export const COMPANY = 'COMPANY'
export const CLIENT_TYPE = [PRIVATE, SELF_EMPLOYED, COMPANY]

// ALTAS DATA
export const MODELS = [
  'EG8141A5',
  'EG8145V5',
  'EG8247H5',
  'HG8245Q2',
  'NC-WR644GACV',
  'SOFTPHONE'
]

// COMMENT TYPES
export const CALL_AGAIN = 'CALL_AGAIN'
export const COVERAGE = 'COVERAGE'
export const INCIDENCE = 'INCIDENCE'
export const TECHNICAL = 'TECHNICAL'
export const SYSTEM = 'SYSTEM'
export const CLIENT_CREATED = 'CLIENT_CREATED'
export const COMMENT_TYPES = [
  CALL_AGAIN,
  COVERAGE,
  INCIDENCE,
  TECHNICAL,
  SYSTEM,
  CLIENT_CREATED
]

export const INFO_COMMENT = 'El cliente ha vuelto a solicitar información'

// ORIGIN OPTIONS
export const SHOP = 'Shop'
export const WEB = 'Web'
export const CALL = 'Call'
export const WHATSAPP = 'Whatsapp'
export const AGENT = 'Agent'
export const ORIGIN_OPTIONS = [SHOP, WEB, CALL, WHATSAPP, AGENT]

// CLIENT STATUS OPTIONS
export const BAJA = 'Baja'
export const CLIENT_STATUS_OPTIONS = [BAJA]

// CITY OPTIONS
export const CITY_OPTIONS = [
  'El Papiol',
  'El Prat de Llobregat',
  'Molins de Rei',
  'Premià de Mar',
  'Premià de Dalt',
  'Vilassar de Mar',
  'Mataró',
  'Badalona',
  'Barcelona',
  'Esplugues de Llobregat',
  'Hospitalet de Llobregat'
]

// CALENDAR COLORS
export const CALENDAR_COLORS = [
  ThemeByfi.colors.azul,
  ThemeByfi.colors.rojo,
  ThemeByfi.colors.amarillo_oscuro,
  ThemeByfi.colors.lila,
  ThemeByfi.colors.lila_muy_oscuro
]

// CONTRACTED SERVICES STATUS
export const PROVISION_PENDING = 'PROVISION_PENDING'
export const PENDING_INSTALLATION = 'PENDING_INSTALLATION'
export const LAUNCHED_PORTABILITY = 'LAUNCHED_PORTABILITY'
export const CANCELLED_PORTALIBILITY = 'CANCELLED_PORTALIBILITY'
export const PROVISIONED = 'PROVISIONED'

// MEET ORIGIN OPTIONS
export const FRIENDS = 'friends'
export const SOCIAL = 'sn'
export const EXT_AD = 'ext_ad'
export const PRINT_AD = 'print_ad'
export const SHOP_MEET = 'shop'
export const AGENT_MEET = 'agent'
export const OTHER_MEET = 'other'
export const MEET_ORIGIN_OPTIONS = [
  FRIENDS,
  SOCIAL,
  EXT_AD,
  PRINT_AD,
  SHOP_MEET,
  AGENT_MEET,
  OTHER_MEET
]
