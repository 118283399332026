import Icon from '@mdi/react'
import { mdiCheckCircleOutline } from '@mdi/js'
import { INTERNET, LANDLINE_PHONE, MOBILE, TV } from 'utils/constants'

export default function AddTemplate({ service, type }) {
  switch (type) {
    case MOBILE:
      return <MobilePhoneTemplate {...{ service }} />
    case INTERNET:
      return <FOTemplate {...{ service }} />
    case LANDLINE_PHONE:
      return null
    // TODO: Falta plantilla fijo
    case TV:
      return <TVTemplate {...{ service }} />
    default:
      return null
  }
}

// 'Internet', 'Landline Phone', 'Mobile Phone', 'Tv'

function FOTemplate({ service }) {
  const Mb = service.service.title
  return (
    <ul className="my-2">
      <li className="resumen-servicio">
        Fibra simétrica de {Mb} ({Mb} de bajada y {Mb} de subida
        simultáneamente)
      </li>
      <li>
        <Icon path={mdiCheckCircleOutline} />
        <p>
          Cuota de instalación gratuita: No cobramos por la instalación ni por
          el router.
        </p>
      </li>
      <li className="ml-3">
        <Icon path={mdiCheckCircleOutline} />{' '}
        <p>
          Router cedido por Byfi: El router es gratuito para los clientes de
          Byfi. Si te das de baja, puedes devolver el router en cualquiera de
          nuestras tiendas Byfi. Si quieres quedártelo, tendrás que pagar el
          precio del router (150€)
        </p>
      </li>
    </ul>
  )
}

function MobilePhoneTemplate({ service }) {
  const GB = service.service.title
  return (
    <ul className="my-2">
      <li className="resumen-servicio">
        Línea móvil con llamadas ilimitadas y {GB}
      </li>
      <li>
        <Icon path={mdiCheckCircleOutline} />{' '}
        <p>El precio de los SMS es de 0,123966€/SMS</p>
      </li>
      <li>
        <Icon path={mdiCheckCircleOutline} />
        <p>
          Roaming en la Zona 1 (Unión Europea) Algunos países como Andorra y
          Suiza no forman parte de la Zona 1. Consulta la lista de países de la
          Zona 1 en{' '}
          <a href="https://byfi.com/roaming/" target="_blank" rel="noreferrer">
            https://byfi.com/roaming/
          </a>
        </p>
      </li>
      <li>
        <Icon path={mdiCheckCircleOutline} />{' '}
        <p>
          Una vez superados los gigas en tu tarifa, navegarás a menor velocidad
          sin coste adicional (32 Kbps)
        </p>
      </li>
      <li>
        <Icon path={mdiCheckCircleOutline} /> <p>Cuota de alta gratuita</p>
      </li>
    </ul>
  )
}

function TVTemplate({ service }) {
  const pack = service.service.title
  const descriptionPack = service.service.description
  return (
    <ul className="my-2">
      <li className="resumen-servicio">
        Paquete {pack} con {descriptionPack}
      </li>
      <li>
        <Icon path={mdiCheckCircleOutline} />{' '}
        <p>
          Instalación gratuita: No cobramos por la instalación ni por el
          decodificador.
        </p>
      </li>
      <li>
        <Icon path={mdiCheckCircleOutline} />{' '}
        <p>
          Decodificador cedido por Byfi: El decodificador es gratuito para los
          clientes de Byfi. Si te das de baja, puedes devolverlo en cualquiera
          de nuestras tiendas Byfi. Si quieres quedártelo, tendrás que pagar el
          precio del decodificador (60€)
        </p>
      </li>
    </ul>
  )
}
