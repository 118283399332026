import axios from 'axios'
import { TOKEN } from 'utils/constants'

const url =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_PROVISION_API_URL
    : process.env.REACT_APP_API_URL

class LeadsDistribuidores {
  constructor() {
    this.api = axios.create({
      baseURL: `${url}/distribuidores`
    })
    this.api.interceptors.request.use((config) => {
      const storedToken = localStorage.getItem(TOKEN)
      if (storedToken) {
        config.headers = { Authorization: `${storedToken}` }
      }
      return config
    })
  }
  // trae todos los Leads generados por los distribuidores

  async getAllLeads() {
    try {
      const response = await this.api.get('/all')
      return response.data
    } catch (err) {
      return console.error(err)
    }
  }

  // Trae todos los Distribuidores

  async getAllDistribuidores() {
    try {
      const response = await this.api.get('/allDistribuidores')
      return response.data
    } catch (error) {
      console.error(error)
    }
  }
}

const leadDistribuidores = new LeadsDistribuidores()

export default leadDistribuidores
