import IconosByfi from 'components/Branding/IconosByfi'
import { euroFormat } from 'utils/prices'
import { EXTRA, INTERNET, LANDLINE_PHONE, MOBILE, TV } from 'utils/constants'
import { TableResumeStyle } from './ContractStyles'

export default function ServicesTable({ services, rate, isExtra = false }) {
  const displayRows = services?.map((s, i) => {
    const hasExtraOption = Boolean(s.extraOption?.length)

    return (
      <tbody key={`${s._id}_${i}`}>
        <ServicesTableRow service={s} rate={rate} {...{ isExtra }} />
        {hasExtraOption && <ExtraTableRow extra={s.extraOption} />}
      </tbody>
    )
  })

  return (
    <TableResumeStyle className="table">
      <thead>
        <tr>
          <th>Resumen del producto</th>
          <th>Alta</th>
          <th className="right">Cuota mensual</th>
        </tr>
      </thead>
      {displayRows}
    </TableResumeStyle>
  )
}

export function ServicesTableRow({ service, rate, isExtra }) {
  const iconHashMap = {
    MOBILE: <IconosByfi width="24" color="#4911C1" iconType={MOBILE} />,
    INTERNET: <IconosByfi width="24" color="#4911C1" iconType={INTERNET} />,
    TV: <IconosByfi width="24" color="#4911C1" iconType={TV} />,
    LANDLINE_PHONE: (
      <IconosByfi width="24" color="#4911C1" iconType={LANDLINE_PHONE} />
    )
  }
  return (
    <tr>
      <td className="title">
        {iconHashMap[service.type]} <h5>{service.title}</h5>
      </td>
      <td>Gratis</td>
      <td align="right">
        {rate
          ? '-'
          : isExtra
          ? euroFormat(service.extraPrice)
          : euroFormat(service.normalPrice)}
      </td>
    </tr>
  )
}

export function ExtraTableRow({ extra }) {
  const icon = <IconosByfi iconType={EXTRA} width="24" color="#4911C1" />
  const displayExtra = extra.map((e) => (
    <tr key={e._id}>
      <td className="title">
        {icon} <h5>{e.title}</h5>
      </td>
      <td>{e.oneChargeOnly ? euroFormat(e.price) : 'Gratis'}</td>
      <td align="right">
        {e.oneChargeOnly ? euroFormat(0) : e.price ? euroFormat(e.price) : '-'}
      </td>
    </tr>
  ))
  return displayExtra
}
