export default function Greetings({ clientName, changeDate }) {
  const date = new Date(changeDate)

  const fullDate = date.toLocaleDateString('es-ES', {
    day: '2-digit',
    month: 'long',
    year: 'numeric'
  })

  const fullTime = date.toLocaleTimeString('es-ES', { hour12: false })

  return (
    <div>
      <p className="hola">Hola {clientName},</p>
      <p>
        El día {fullDate} a las {fullTime} solicitaste una modificación en tu
        contrato.
      </p>
    </div>
  )
}
