import { UpdateContractStyles } from 'components/Contract/VisualComponents/ContractStyles'
import Change from 'components/UpdateContract/Change'
import ContractResume from 'components/UpdateContract/ContractResume'
import Greetings from 'components/UpdateContract/Greetings'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { LoaderByfi } from 'styles-byfi'

import { BASE_PATH, EXT_CLIENT_AUTH } from 'utils/constants'

export default function UpdateContract() {
  const [contract, setContract] = useState(undefined)
  const [, setError] = useState(undefined)
  const [loading, setLoading] = useState(true)

  const { pathname } = useLocation()
  const contractRef = pathname.slice(pathname.lastIndexOf('/') + 1)

  useEffect(() => {
    getContractData(contractRef)
      .then((res) => res.json())
      .then((data) => setContract(data))
      .catch((e) => setError(e))
      .finally(() => setLoading(false))
  }, [])

  if (loading) return <LoaderByfi />

  const { client, history } = contract

  const clientName = `${client.name} ${client.lastname1} ${client.lastname2}`
  const phoneNumber = client.phoneNumber

  const lastChangeElement = history[history.length - 1]
  const changeDate = lastChangeElement.createdAt

  return (
    <UpdateContractStyles>
      <div className="container">
        <Greetings {...{ clientName, changeDate }} />
        <Change data={{ clientName, lastChangeElement, phoneNumber }} />
        <ContractResume data={contract} />
      </div>
    </UpdateContractStyles>
  )
}

function getContractData(contractRef) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Basic ${EXT_CLIENT_AUTH}`
    },
    method: 'GET'
  }

  const url = `${BASE_PATH}/extclient/contract/${contractRef}`
  return fetch(url, options)
}
