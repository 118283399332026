import useAuth from 'hooks/useAuth'
import { Route, Redirect } from 'react-router-dom'
import { ADMIN } from 'utils/constants'

export default function ProtectedRoute({
  authorizedRoles,
  Component,
  ...rest
}) {
  const {
    auth: { role }
  } = useAuth()

  const isAdmin = role === ADMIN
  const isAuthorized = authorizedRoles?.includes(role) || isAdmin

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthorized ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  )
}
