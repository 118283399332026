import styled from 'styled-components'
import { ThemeByfi } from 'styles-byfi'

export const TopNavbarStyled = styled.div`
  background-color: ${({ warningCalls }) =>
    warningCalls > 0
      ? ThemeByfi.colors.rojo_oscuro
      : ThemeByfi.colors.lila_muy_oscuro};
  padding: 8px 0;
  z-index: 1;

  h4 {
    display: inline-block;
    color: white;
    font-weight: 600;
    font-size: 14px;
    border-bottom: 2px solid white;
  }

  .ayuda {
    color: white;
    font-weight: 600;
    font-size: 14px;
    font-style: italic;
  }
`

export const NavbarStyled = styled.div`
  background-color: ${ThemeByfi.colors.blanco};
  position: sticky;
  top: 0px;
  z-index: 10;
  box-shadow: 0px 6px 37px rgba(43, 17, 172, 0.1);

  .navbar-logo {
    max-width: 180px;
    position: absolute;
    top: 0;
  }

  .navbar-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .nav-drop {
    margin-top: -2px;
  }

  nav {
    display: flex;
    align-items: center;

    ul {
      display: flex;

      .item {
        position: relative;
        cursor: pointer;

        .text-link {
          font-size: 15px;
          font-weight: 500;
          font-style: italic;
        }

        a {
          font-size: 15px;
          font-style: italic;
          color: ${ThemeByfi.colors.gris_muy_oscuro};
          padding: 20px 0 16px 32px;
          line-height: 1em;
          display: block;
          font-weight: 500;
          transition: all 0.5s ease;
        }

        &:hover {
          a {
            color: ${ThemeByfi.colors.lila};
            font-weight: 600;
          }
        }
      }
    }

    .submenu {
      display: none;
      transition: transform 0.5s ease;
    }
    .item:hover .submenu {
      display: block;
      position: absolute;
      inset: 0px auto auto 0px;
      margin: 0;
      transform: translate(30px, 42px);
      z-index: 99;

      .submenu-container {
        margin-top: 32px;
        min-width: 200px;
        padding: 24px;
        box-shadow: 0px 6px 30px rgba(123, 97, 255, 0.2);
        border-radius: 20px;
        background-color: ${ThemeByfi.colors.blanco};
      }

      a {
        font-family: ${ThemeByfi.fonts.montserrat};
        font-size: 16px;
        color: ${ThemeByfi.colors.gris_muy_oscuro};
        padding: 12px;
        font-style: normal;

        &:hover {
          color: ${ThemeByfi.colors.lila};
          font-style: italic;
        }
      }
    }
  }

  .active-nav-item {
    color: ${ThemeByfi.colors.lila} !important;
    font-weight: 700;
  }

  .user-icon {
    margin-left: 20px;
    padding: 16px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    background-color: ${ThemeByfi.colors.azul_super_claro};
    color: ${ThemeByfi.colors.azul};
    font-weight: 700;
    font-size: 15px;
    cursor: pointer;

    h4 {
      font-style: italic;
      font-size: 18px;
      padding: 6px;
    }

    .submenu {
      display: none;
      transition: transform 0.5s ease;
    }

    &:hover .submenu {
      display: block;
      position: absolute;
      margin: 0;
      transform: translate(-10px, 100px);
      z-index: 99;

      .submenu-container {
        margin-top: 32px;
        min-width: 200px;
        padding: 18px;
        box-shadow: 0px 6px 30px rgba(123, 97, 255, 0.2);
        border-radius: 20px;
        background-color: ${ThemeByfi.colors.blanco};
      }

      a {
        font-family: ${ThemeByfi.fonts.montserrat};
        font-size: 16px;
        color: ${ThemeByfi.colors.gris_oscuro};
        padding: 12px;
        font-style: normal;

        &:hover {
          color: ${ThemeByfi.colors.gris_muy_oscuro};
          font-style: italic;
        }
      }
    }
  }

  @media (max-width: 1000px) {
    padding: 12px;

    nav {
      ul {
        display: none;
      }
    }
  }
`

export const NavbarMenuMobileStyled = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999999;
  padding: 6px 12px;
  background: ${ThemeByfi.colors.lila_oscuro};

  .item {
    width: 33%;
    .nav-link {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: ${ThemeByfi.fonts.montserrat};
      font-size: 12px;
      color: white;
      text-transform: uppercase;
      font-weight: 600;
      svg {
        margin-bottom: 4px;
      }
    }

    .active-nav-item {
      color: ${ThemeByfi.colors.amarillo};
    }
  }

  @media (min-width: 1000px) {
    display: none;
  }
`

export const BadgeLeads = styled.span`
  background-color: ${ThemeByfi.colors.amarillo};
  color: ${ThemeByfi.colors.lila};
  font-size: 11px;
  padding: 3px 6px;
  font-weight: 600;
  border-radius: 20px;
  margin-left: 8px;
`

export const ButtonContract = styled.button`
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 14px 20px !important;
  margin: 10px 0;
  border-radius: 16px;
  background-color: ${ThemeByfi.colors.azul};
  color: ${ThemeByfi.colors.blanco};
  text-transform: uppercase;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.5px;
  transition: background-color 0.5s ease;

  &:hover {
    text-decoration: none !important;
    background-color: ${ThemeByfi.colors.lila_oscuro};
    box-shadow: ${ThemeByfi.shadows.main_shadow};
  }

  &:focus,
  .focus {
    outline: 0;
    box-shadow: none;
  }
`
