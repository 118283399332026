import { INTERNET, LANDLINE_PHONE, MOBILE, TV } from 'utils/constants'

export default function IconosByfi({ color, width, iconType }) {
  const iconoFibra = (
    <svg
      width={width}
      height={width}
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M44.2841 77C52.0653 77 58.3732 70.6839 58.3732 62.8927C58.3732 55.1015 52.0653 48.7855 44.2841 48.7855C36.5029 48.7855 30.195 55.1015 30.195 62.8927C30.195 70.6839 36.5029 77 44.2841 77Z"
          fill={color}
        />
        <path
          d="M20.2342 50.7954C19.2873 50.7954 18.3215 50.511 17.4788 49.9232C15.3106 48.3968 14.7898 45.4009 16.3048 43.2298C18.4825 40.1296 21.1337 37.4276 24.2015 35.1997C31.549 29.862 40.5725 27.7099 49.6338 29.151C58.6856 30.592 66.6108 35.4272 71.9415 42.7842C73.4943 44.9268 73.0209 47.9322 70.881 49.4965C68.7317 51.0608 65.7397 50.5773 64.1774 48.4347C60.3521 43.154 54.6521 39.684 48.1283 38.6411C41.6045 37.6077 35.1091 39.1436 29.8352 42.9833C27.629 44.5855 25.7259 46.5291 24.1541 48.7665C23.2262 50.0843 21.7397 50.7954 20.2342 50.7954Z"
          fill={color}
        />
        <path
          d="M4.79087 40.2057C3.84402 40.2057 2.8877 39.9307 2.05448 39.3429C-0.123272 37.826 -0.653507 34.8396 0.851981 32.659C4.24169 27.7765 8.39835 23.5291 13.1989 20.0402C24.561 11.7826 38.527 8.44536 52.5498 10.6828C66.5632 12.9108 78.8249 20.4005 87.0814 31.7773C88.6342 33.92 88.1608 36.9253 86.0209 38.4897C83.8716 40.054 80.8701 39.5705 79.3172 37.4278C72.5757 28.1273 62.5296 22.0027 51.0538 20.1825C39.5686 18.3527 28.1307 21.0736 18.8421 27.8334C14.9127 30.687 11.5135 34.1665 8.72975 38.1673C7.80184 39.4851 6.31529 40.2057 4.79087 40.2057Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="88"
            height="67"
            fill="white"
            transform="translate(0 10)"
          />
        </clipPath>
      </defs>
    </svg>
  )
  const iconoTv = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={width}
      viewBox="0 0 100 100"
    >
      <g id="Capa_1" data-name="Capa 1">
        <path
          d="M88.58,69.39H10.46A4.36,4.36,0,0,1,6.1,65V18.81a4.36,4.36,0,0,1,4.36-4.36H88.58a4.36,4.36,0,0,1,4.36,4.36V65A4.36,4.36,0,0,1,88.58,69.39ZM14.82,60.66h69.4V23.18H14.82Z"
          fill={color}
        />
        <path
          d="M49.52,80.37A4.36,4.36,0,0,1,45.16,76V65a4.36,4.36,0,1,1,8.72,0V76A4.36,4.36,0,0,1,49.52,80.37Z"
          fill={color}
        />
        <path
          d="M29.1,87.84a4.36,4.36,0,0,1-2.77-7.73c5.69-4.69,12.41-6.59,23.19-6.59,14.57,0,21,4.32,23.53,6.9a4.36,4.36,0,0,1-6.22,6.11c-2-2.07-7.66-4.29-17.31-4.29-11.12,0-14.94,2.38-17.65,4.6A4.33,4.33,0,0,1,29.1,87.84Z"
          fill={color}
        />
        <path
          d="M37.59,64.28a2.91,2.91,0,0,1-2.69-1.81L17.92,21a2.91,2.91,0,0,1,5.38-2.2l17,41.46a2.9,2.9,0,0,1-1.59,3.79A2.84,2.84,0,0,1,37.59,64.28Z"
          fill={color}
        />
        <path
          d="M51.9,64.28a2.91,2.91,0,0,1-2.7-1.81L32.23,21a2.91,2.91,0,0,1,5.38-2.2l17,41.46a2.91,2.91,0,0,1-2.68,4Z"
          fill={color}
        />
      </g>
    </svg>
  )
  const iconoMovil = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={width}
      viewBox="0 0 100 100"
    >
      <g id="Capa_1" data-name="Capa 1">
        <path
          d="M61.61,94.18H42.71A14.85,14.85,0,0,1,27.88,79.35V28A14.86,14.86,0,0,1,42.71,13.11h18.9A14.85,14.85,0,0,1,76.44,28v51.4A14.84,14.84,0,0,1,61.61,94.18Zm-18.9-73A6.75,6.75,0,0,0,36,28v51.4a6.76,6.76,0,0,0,6.75,6.75h18.9a6.76,6.76,0,0,0,6.75-6.75V28a6.75,6.75,0,0,0-6.75-6.75Z"
          fill={color}
        />
        <path
          d="M70.75,79.65H33.57a2.69,2.69,0,1,1,0-5.38H70.75a2.69,2.69,0,1,1,0,5.38Z"
          fill={color}
        />
        <path
          d="M70.75,31.73H33.57a2.7,2.7,0,1,1,0-5.39H70.75a2.7,2.7,0,1,1,0,5.39Z"
          fill={color}
        />
        <path
          d="M60.33,21.2a4,4,0,0,1-4-4.05V9.86a4,4,0,0,1,8.08,0v7.29A4,4,0,0,1,60.33,21.2Z"
          fill={color}
        />
      </g>
    </svg>
  )

  const iconoFijo = (
    <svg
      width={width}
      height={width}
      viewBox="0 0 462 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M457.099 101.573L443.47 87.9439C326.216 -29.3103 135.793 -29.3183 18.5302 87.9439L4.90138 101.573C-0.682775 107.157 -0.992339 116.109 4.19209 122.065L72.327 200.341C77.2309 205.976 85.53 207.152 91.8054 203.104L164.909 155.961C169.564 152.959 172.191 147.64 171.744 142.12L169.427 113.458C209.256 100.392 252.696 100.385 292.541 113.447L290.242 142.125C289.8 147.642 292.426 152.956 297.078 155.956L370.195 203.103C376.471 207.151 384.769 205.975 389.673 200.34L457.808 122.064C462.992 116.109 462.683 107.156 457.099 101.573V101.573ZM375.546 170.794L320.919 135.569L323.419 104.397C323.949 97.7888 320.086 91.6146 313.911 89.2002C261.75 68.8051 202.31 67.9866 148.047 89.2172C141.87 91.6336 138.008 97.8129 138.543 104.423L141.062 135.577L86.4527 170.793L36.0681 112.908L39.7818 109.195C145.292 3.6857 316.702 3.67969 422.218 109.195L425.931 112.908L375.546 170.794Z"
        fill={color}
      />
      <path
        d="M422.535 298.978L348.511 265.985L319.168 212.037C316.537 207.2 311.473 204.189 305.967 204.189H276.081V179.146C276.081 170.847 269.353 164.118 261.054 164.118C252.755 164.118 246.027 170.847 246.027 179.146V204.19H215.972V179.146C215.972 170.847 209.244 164.118 200.945 164.118C192.646 164.118 185.918 170.847 185.918 179.146V204.19H156.417C150.938 204.19 145.893 207.173 143.251 211.973L113.539 265.969L39.4662 298.978C34.0464 301.393 30.555 306.771 30.555 312.704V476.936C30.555 485.235 37.2833 491.964 45.5824 491.964H64.7011V496.974C64.7011 505.273 71.4293 512.001 79.7284 512.001C88.0275 512.001 94.7558 505.273 94.7558 496.974V491.964H367.243V496.974C367.243 505.273 373.971 512.001 382.271 512.001C390.57 512.001 397.298 505.273 397.298 496.974V491.964H416.418C424.717 491.964 431.445 485.235 431.445 476.936V312.704C431.444 306.771 427.954 301.393 422.535 298.978V298.978ZM401.389 461.908H60.6097V322.458L130.386 291.364C133.379 290.029 135.855 287.753 137.434 284.882L165.299 234.244H297.033L324.541 284.817C326.118 287.718 328.608 290.018 331.623 291.363L401.388 322.457V461.908H401.389Z"
        fill={color}
      />
      <path
        d="M230.999 281.577C189.569 281.577 155.863 315.283 155.863 356.714C155.863 398.144 189.569 431.85 230.999 431.85C272.43 431.85 306.136 398.144 306.136 356.714C306.136 315.283 272.43 281.577 230.999 281.577ZM230.999 401.796C206.141 401.796 185.918 381.572 185.918 356.714C185.918 331.855 206.141 311.632 230.999 311.632C255.858 311.632 276.081 331.855 276.081 356.714C276.081 381.572 255.858 401.796 230.999 401.796Z"
        fill={color}
      />
      <path
        d="M230.999 371.746C239.299 371.746 246.027 365.018 246.027 356.719C246.027 348.419 239.299 341.691 230.999 341.691C222.7 341.691 215.972 348.419 215.972 356.719C215.972 365.018 222.7 371.746 230.999 371.746Z"
        fill={color}
      />
    </svg>
  )

  const iconoExtra = (
    <svg width={width} height={width} viewBox="0 0 24 24">
      <path fill={color} d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
    </svg>
  )

  const rateIcon = (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.8243 22.7297H1.17567C1.07174 22.7297 0.972059 22.6884 0.898565 22.615C0.825071 22.5415 0.783783 22.4418 0.783783 22.3378V18.3405C0.783765 18.2502 0.814961 18.1626 0.872091 18.0926C0.929222 18.0227 1.00878 17.9746 1.0973 17.9565C1.89461 17.7946 2.61143 17.362 3.12631 16.7321C3.64119 16.1022 3.92245 15.3136 3.92245 14.5C3.92245 13.6864 3.64119 12.8979 3.12631 12.2679C2.61143 11.638 1.89461 11.2054 1.0973 11.0435C1.00878 11.0255 0.929222 10.9774 0.872091 10.9074C0.814961 10.8374 0.783765 10.7498 0.783783 10.6595V6.66217C0.783783 6.55823 0.825071 6.45856 0.898565 6.38506C0.972059 6.31157 1.07174 6.27028 1.17567 6.27028H27.8243C27.9283 6.27028 28.0279 6.31157 28.1014 6.38506C28.1749 6.45856 28.2162 6.55823 28.2162 6.66217V10.6595C28.2162 10.7498 28.185 10.8374 28.1279 10.9074C28.0708 10.9774 27.9912 11.0255 27.9027 11.0435C27.1054 11.2054 26.3886 11.638 25.8737 12.2679C25.3588 12.8979 25.0775 13.6864 25.0775 14.5C25.0775 15.3136 25.3588 16.1022 25.8737 16.7321C26.3886 17.362 27.1054 17.7946 27.9027 17.9565C27.9912 17.9746 28.0708 18.0227 28.1279 18.0926C28.185 18.1626 28.2162 18.2502 28.2162 18.3405V22.3378C28.2162 22.4418 28.1749 22.5415 28.1014 22.615C28.0279 22.6884 27.9283 22.7297 27.8243 22.7297ZM1.56757 21.946H27.4324V18.6482C26.529 18.3927 25.7337 17.8494 25.1674 17.1007C24.601 16.352 24.2945 15.4388 24.2945 14.5C24.2945 13.5612 24.601 12.6481 25.1674 11.8993C25.7337 11.1506 26.529 10.6073 27.4324 10.3518V7.05406H1.56757V10.3518C2.47095 10.6073 3.26626 11.1506 3.83262 11.8993C4.39899 12.6481 4.70546 13.5612 4.70546 14.5C4.70546 15.4388 4.39899 16.352 3.83262 17.1007C3.26626 17.8494 2.47095 18.3927 1.56757 18.6482V21.946Z"
        fill="#4911C1"
      />
      <path
        d="M20.3784 8.47898C20.2744 8.47898 20.1748 8.43769 20.1013 8.3642C20.0278 8.29071 19.9865 8.19103 19.9865 8.08709V6.66217C19.9865 6.55823 20.0278 6.45856 20.1013 6.38506C20.1748 6.31157 20.2744 6.27028 20.3784 6.27028C20.4823 6.27028 20.582 6.31157 20.6555 6.38506C20.729 6.45856 20.7703 6.55823 20.7703 6.66217V8.08709C20.7703 8.19103 20.729 8.29071 20.6555 8.3642C20.582 8.43769 20.4823 8.47898 20.3784 8.47898Z"
        fill="#4911C1"
      />
      <path
        d="M20.3784 12.0417C20.2744 12.0417 20.1748 12.0004 20.1013 11.9269C20.0278 11.8534 19.9865 11.7537 19.9865 11.6498V10.2249C19.9865 10.1209 20.0278 10.0212 20.1013 9.94774C20.1748 9.87425 20.2744 9.83296 20.3784 9.83296C20.4823 9.83296 20.582 9.87425 20.6555 9.94774C20.729 10.0212 20.7703 10.1209 20.7703 10.2249V11.6498C20.7703 11.7537 20.729 11.8534 20.6555 11.9269C20.582 12.0004 20.4823 12.0417 20.3784 12.0417Z"
        fill="#4911C1"
      />
      <path
        d="M20.3784 15.6043C20.2744 15.6043 20.1748 15.5631 20.1013 15.4896C20.0278 15.4161 19.9865 15.3164 19.9865 15.2125V13.7875C19.9865 13.6836 20.0278 13.5839 20.1013 13.5104C20.1748 13.4369 20.2744 13.3956 20.3784 13.3956C20.4823 13.3956 20.582 13.4369 20.6555 13.5104C20.729 13.5839 20.7703 13.6836 20.7703 13.7875V15.2128C20.7702 15.3167 20.7288 15.4163 20.6554 15.4897C20.5819 15.5631 20.4823 15.6043 20.3784 15.6043Z"
        fill="#4911C1"
      />
      <path
        d="M20.3784 19.167C20.2744 19.167 20.1748 19.1258 20.1013 19.0523C20.0278 18.9788 19.9865 18.8791 19.9865 18.7752V17.3502C19.9865 17.2463 20.0278 17.1466 20.1013 17.0731C20.1748 16.9996 20.2744 16.9583 20.3784 16.9583C20.4823 16.9583 20.582 16.9996 20.6555 17.0731C20.729 17.1466 20.7703 17.2463 20.7703 17.3502V18.7752C20.7703 18.8791 20.729 18.9788 20.6555 19.0523C20.582 19.1258 20.4823 19.167 20.3784 19.167Z"
        fill="#4911C1"
      />
      <path
        d="M20.3784 22.7297C20.2744 22.7297 20.1748 22.6884 20.1013 22.615C20.0278 22.5415 19.9865 22.4418 19.9865 22.3378V20.9129C19.9865 20.809 20.0278 20.7093 20.1013 20.6358C20.1748 20.5623 20.2744 20.521 20.3784 20.521C20.4823 20.521 20.582 20.5623 20.6555 20.6358C20.729 20.7093 20.7703 20.809 20.7703 20.9129V22.3378C20.7703 22.4418 20.729 22.5415 20.6555 22.615C20.582 22.6884 20.4823 22.7297 20.3784 22.7297Z"
        fill="#4911C1"
      />
      <path
        d="M8.2019 18.6313C8.1244 18.6313 8.04865 18.6083 7.98422 18.5652C7.91979 18.5222 7.86957 18.461 7.83992 18.3894C7.81026 18.3178 7.8025 18.239 7.81761 18.163C7.83273 18.087 7.87004 18.0172 7.92483 17.9624L15.1874 10.6959C15.2235 10.6585 15.2668 10.6286 15.3146 10.6081C15.3624 10.5875 15.4138 10.5767 15.4658 10.5763C15.5179 10.5758 15.5695 10.5857 15.6176 10.6054C15.6658 10.6252 15.7096 10.6543 15.7464 10.691C15.7832 10.7278 15.8123 10.7716 15.832 10.8198C15.8517 10.8679 15.8616 10.9195 15.8611 10.9716C15.8607 11.0236 15.8499 11.075 15.8293 11.1228C15.8088 11.1706 15.7789 11.2139 15.7415 11.25L8.47896 18.5169C8.40542 18.5902 8.30577 18.6314 8.2019 18.6313Z"
        fill="#4911C1"
      />
      <path
        d="M14.2574 18.8183C13.8994 18.8183 13.5494 18.7121 13.2518 18.5132C12.9541 18.3143 12.7221 18.0316 12.5851 17.7008C12.4481 17.3701 12.4122 17.0061 12.4821 16.655C12.5519 16.3038 12.7243 15.9813 12.9775 15.7281C13.2306 15.475 13.5532 15.3026 13.9043 15.2327C14.2554 15.1629 14.6194 15.1987 14.9501 15.3357C15.2809 15.4727 15.5636 15.7048 15.7625 16.0024C15.9614 16.3001 16.0676 16.6501 16.0676 17.0081C16.067 17.488 15.876 17.9481 15.5367 18.2874C15.1974 18.6267 14.7373 18.8176 14.2574 18.8183ZM14.2574 15.9813C14.0545 15.9813 13.856 16.0415 13.6873 16.1543C13.5185 16.2671 13.3869 16.4273 13.3092 16.6149C13.2315 16.8024 13.2112 17.0087 13.2508 17.2078C13.2903 17.4069 13.388 17.5898 13.5315 17.7333C13.675 17.8769 13.8579 17.9747 14.057 18.0143C14.256 18.054 14.4624 18.0337 14.6499 17.9561C14.8375 17.8784 14.9978 17.747 15.1106 17.5782C15.2235 17.4095 15.2837 17.2111 15.2838 17.0081C15.2836 16.7359 15.1754 16.4749 14.983 16.2824C14.7905 16.0899 14.5296 15.9817 14.2574 15.9813Z"
        fill="#4911C1"
      />
      <path
        d="M9.2561 13.7777C8.8981 13.7777 8.54815 13.6716 8.25048 13.4727C7.95281 13.2738 7.7208 12.9911 7.58378 12.6604C7.44676 12.3297 7.41089 11.9657 7.48071 11.6146C7.55052 11.2635 7.72288 10.9409 7.976 10.6878C8.22911 10.4346 8.55161 10.2622 8.90272 10.1923C9.25383 10.1224 9.61778 10.1582 9.94854 10.2951C10.2793 10.4321 10.562 10.664 10.761 10.9616C10.9599 11.2593 11.0662 11.6092 11.0662 11.9672C11.0657 12.4472 10.8749 12.9073 10.5355 13.2467C10.1962 13.5862 9.73606 13.7771 9.2561 13.7777ZM9.2561 10.9408C9.05311 10.9408 8.85467 11.001 8.68588 11.1138C8.5171 11.2266 8.38555 11.3869 8.30786 11.5744C8.23018 11.762 8.20986 11.9683 8.24946 12.1674C8.28906 12.3665 8.38681 12.5494 8.53035 12.6929C8.67389 12.8365 8.85677 12.9342 9.05587 12.9738C9.25496 13.0134 9.46133 12.9931 9.64887 12.9154C9.83642 12.8378 9.99671 12.7062 10.1095 12.5374C10.2223 12.3686 10.2825 12.1702 10.2825 11.9672C10.2822 11.6951 10.1739 11.4342 9.98151 11.2418C9.78909 11.0494 9.52821 10.9411 9.2561 10.9408Z"
        fill="#4911C1"
      />
    </svg>
  )

  const iconoUser = (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.48017 0H28.2875C30.1661 0 31.9676 0.746237 33.2959 2.07455C34.6243 3.40286 35.3705 5.20443 35.3705 7.08295V34.9733H7.48017C5.60165 34.9733 3.80008 34.227 2.47176 32.8987C1.14345 31.5704 0.397217 29.7688 0.397217 27.8903V7.08295C0.397217 5.20443 1.14345 3.40286 2.47176 2.07455C3.80008 0.746237 5.60165 0 7.48017 0Z"
        fill="#27D6C9"
      />
      <path
        d="M17.4378 14.1927C17.929 14.2026 18.4172 14.1139 18.8735 13.9319C19.3298 13.7498 19.745 13.4781 20.0946 13.1329C20.4441 12.7876 20.7208 12.3758 20.9084 11.9217C21.0961 11.4677 21.1907 10.9806 21.1869 10.4893C21.2022 9.99036 21.1172 9.49339 20.9368 9.02791C20.7564 8.56243 20.4844 8.13792 20.1369 7.77955C19.7894 7.42118 19.3734 7.13626 18.9137 6.94168C18.454 6.74711 17.9599 6.64685 17.4607 6.64685C16.9614 6.64685 16.4673 6.74711 16.0076 6.94168C15.5479 7.13626 15.1319 7.42118 14.7844 7.77955C14.4369 8.13792 14.1649 8.56243 13.9845 9.02791C13.8041 9.49339 13.7191 9.99036 13.7344 10.4893C13.7302 10.9768 13.8231 11.4603 14.0077 11.9115C14.1923 12.3627 14.465 12.7727 14.8097 13.1174C15.1544 13.4621 15.5644 13.7348 16.0156 13.9194C16.4668 14.104 16.9503 14.1969 17.4378 14.1927Z"
        fill="#4911C1"
      />
      <path
        d="M26.7579 15.3427H22.9526C22.8225 15.3422 22.6936 15.3674 22.5733 15.4169C22.453 15.4663 22.3436 15.5391 22.2515 15.6309C22.1593 15.7227 22.0862 15.8318 22.0363 15.9519C21.9865 16.0721 21.9608 16.2009 21.9608 16.3309V21.1844C21.9608 21.7751 21.8444 22.36 21.6184 22.9057C21.3923 23.4515 21.061 23.9474 20.6433 24.3651C20.2256 24.7828 19.7297 25.1141 19.184 25.3402C18.6382 25.5662 18.0533 25.6826 17.4626 25.6826C16.8719 25.6826 16.2869 25.5662 15.7412 25.3402C15.1954 25.1141 14.6996 24.7828 14.2819 24.3651C13.8642 23.9474 13.5328 23.4515 13.3068 22.9057C13.0807 22.36 12.9644 21.7751 12.9644 21.1844V16.3309C12.9644 16.2009 12.9387 16.0721 12.8888 15.9519C12.8389 15.8318 12.7658 15.7227 12.6737 15.6309C12.5815 15.5391 12.4722 15.4663 12.3519 15.4169C12.2316 15.3674 12.1027 15.3422 11.9726 15.3427H8.17075C8.04054 15.3417 7.91144 15.3666 7.79092 15.4159C7.6704 15.4652 7.56086 15.5379 7.46862 15.6298C7.37639 15.7218 7.30329 15.831 7.25357 15.9514C7.20385 16.0717 7.17849 16.2007 7.17896 16.3309V21.1844C7.17971 23.4061 7.89951 25.568 9.23075 27.3468C10.562 29.1256 12.4332 30.4258 14.5646 31.053V39.8102C14.5646 40.0733 14.6691 40.3255 14.8551 40.5115C15.0411 40.6975 15.2933 40.802 15.5564 40.802H19.3617C19.6248 40.802 19.877 40.6975 20.063 40.5115C20.249 40.3255 20.3535 40.0733 20.3535 39.8102V31.053C22.4849 30.4258 24.3561 29.1256 25.6874 27.3468C27.0186 25.568 27.7384 23.4061 27.7392 21.1844V16.3309C27.7373 16.0706 27.6336 15.8214 27.4502 15.6366C27.2667 15.4519 27.0182 15.3463 26.7579 15.3427Z"
        fill="#4911C1"
      />
    </svg>
  )

  switch (iconType) {
    case INTERNET: {
      return iconoFibra
    }
    case TV: {
      return iconoTv
    }
    case MOBILE: {
      return iconoMovil
    }
    case LANDLINE_PHONE: {
      return iconoFijo
    }
    case 'RATE': {
      return rateIcon
    }
    case 'EXTRA': {
      return iconoExtra
    }
    default: {
      return iconoUser
    }
  }
}
