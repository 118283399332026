import React from 'react'
const DistribuidoresLeads = ({ leads, distribuidores }) => {
  const putDistribuidorName = (distribuidorIDonLead) => {
    const result = distribuidores.find(
      (distribuidor) => distribuidor._id === distribuidorIDonLead
    )
    return result ? result.id : 'Distribuidor desconocido'
  }

  // función para indicar fecha en dd/mm/aaaa
  function formatDate(date) {
    const formatedDate = new Date(date)
    formatedDate.setDate(formatedDate.getDate() + 1)
    const day = formatedDate.getDate()
    const month = formatedDate.getMonth() + 1
    const year = formatedDate.getFullYear().toString().slice(-2)

    const dayFormated = day.toString().padStart(2, '0')
    const monthFormated = month.toString().padStart(2, '0')

    return `${dayFormated}/${monthFormated}/${year}` || 'Sin fecha'
  }

  return (
    <table>
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Dirección</th>
          <th>Teléfono</th>
          <th>Fecha</th>
          <th>Información</th>
          <th>Distribuidor</th>
        </tr>
      </thead>
      <tbody>
        {leads &&
          leads.map((lead) => {
            return (
              <tr key={lead._id}>
                <td>{lead.name}</td>
                <td>
                  {lead.address} {lead.municipio}
                </td>
                <td>{lead.phoneNumber}</td>
                <td>{formatDate(lead.createdAt)}</td>
                <td>
                  {lead.infoFromDistribuidor &&
                  lead.infoFromDistribuidor.length > 0
                    ? lead.infoFromDistribuidor
                    : 'Sin información'}
                </td>
                <td>{putDistribuidorName(lead.distribuidorID)}</td>
              </tr>
            )
          })}
      </tbody>
    </table>
  )
}

export default DistribuidoresLeads
