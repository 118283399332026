import AddTemplate from './AddTemplate'
import RemoveTemplate from './RemoveTemplate'

export default function Change({ data }) {
  const displayLastChanges = data.lastChangeElement.changes.map((e) => {
    switch (e.action) {
      case 'ADD':
        return (
          <AddTemplate
            service={e.service}
            type={e.service.type}
            key={e.service._id}
          />
        )
      case 'REMOVE':
        return <RemoveTemplate type={e.service.type} />
      case 'UPDATE':
        return <h1>UPDATE</h1>
      default:
        return null
    }
  })

  return (
    <div className="cambios-realizados">
      <h2>A continuación, te explicamos los cambios que has realizado:</h2>
      <KeyValue label="Nombre del titular" value={data.clientName} />
      <KeyValue label="Teléfono de contacto" value={data.phoneNumber} />
      <h3>¿Qué has solicitado?</h3>
      {displayLastChanges}
    </div>
  )
}

function KeyValue({ value, label }) {
  return (
    <div className="my-1">
      <p>
        <strong>{label}:</strong> {value}
      </p>
    </div>
  )
}
