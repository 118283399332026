import { Link } from 'react-router-dom'
import { callAgainComments } from 'utils/clients'
import { TopNavbarStyled } from './NavbarStyle'

export default function TopNavbar({ clients }) {
  const callAgain = callAgainComments(clients).map((c) => ({
    contactDate: c.contactDate
  }))

  const warningCalls = callAgain.filter(
    (call) => call.contactDate < new Date()
  ).length

  return (
    <TopNavbarStyled warningCalls={warningCalls}>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            {warningCalls > 0 && (
              <Link to="/">
                <h4>Hay {warningCalls} llamadas pasadas de fecha</h4>
              </Link>
            )}
          </div>
          <div className="col-md-6 text-right">
            <Link to="/ayuda" className="ayuda">
              Preguntas frecuentes
            </Link>
            <Link to="/manual" className="ayuda ml-4">
              Manual Byfi
            </Link>
          </div>
        </div>
      </div>
    </TopNavbarStyled>
  )
}
