import DistribuidoresLeads from 'components/Tables/DistribuidoresLeads'
import React, { useEffect, useState } from 'react'
import leadDistribuidores from 'services/LeadsDistribuidores'
import { TabsByfi } from 'styles-byfi'

const LeadsDistribuidores = () => {
  const [pendingLeads, setPendingLeads] = useState([])
  const [callAgainLeads, setcallAgainLeads] = useState([])
  const [lostLeads, setLostLeads] = useState([])
  const [distribuidores, setDistribuidores] = useState([])

  const getAllLeads = async () => {
    const leadsFromDB = await leadDistribuidores.getAllLeads()

    if (leadsFromDB.length > 0) {
      const filteredPendingLeads = leadsFromDB.filter(
        (elem) => elem.status === 'pending'
      )
      setPendingLeads(filteredPendingLeads)

      const filteredCallAgainLeads = leadsFromDB.filter(
        (elem) => elem.status === 'callAgain'
      )
      setcallAgainLeads(filteredCallAgainLeads)

      const filteredCancelLeads = leadsFromDB.filter(
        (elem) => elem.status === 'cancel'
      )
      setLostLeads(filteredCancelLeads)
    }
  }
  const getAllDistribuidores = async () => {
    const response = await leadDistribuidores.getAllDistribuidores()
    if (response.length > 0) {
      setDistribuidores(response)
    }
  }
  useEffect(() => {
    getAllLeads()
    getAllDistribuidores()
  }, [])
  return (
    <TabsByfi key={Math.random()}>
      <div
        label={`Leads ${
          pendingLeads.length > 0 ? `${pendingLeads.length}` : '0'
        }`}
      >
        {pendingLeads.length === 0 && <h3>No hay Leads pendientes</h3>}
        {pendingLeads.length > 0 && (
          <DistribuidoresLeads
            leads={pendingLeads}
            distribuidores={distribuidores}
          />
        )}
      </div>

      <div
        label={`Llamar otra vez ${
          callAgainLeads.length > 0 ? `${callAgainLeads.length}` : '0'
        }`}
      >
        leads para llamar
        {!callAgainLeads && <h3>No hay Leads para llamar</h3>}
      </div>

      <div
        label={`Perdidos ${lostLeads.length > 0 ? `${lostLeads.length}` : '0'}`}
      >
        leads perdidos
        {!lostLeads && <h3>No hay Leads perdidios</h3>}
      </div>
    </TabsByfi>
  )
}

export default LeadsDistribuidores
