import { NavLink } from 'react-router-dom'
import { mdiChevronDown } from '@mdi/js'
import Icon from '@mdi/react'

import { ROLES } from 'utils/constants'

import { BadgeLeads } from './NavbarStyle'

export default function NavBarElements({ role, newLeads, clientDemandInfo }) {
  switch (role) {
    case ROLES.USER:
      return (
        <ul>
          <NavElement to="/leads" text="Leads" badge={newLeads} />
          <NavElement to="/clientes" text="Clientes" badge={clientDemandInfo} />
          <NavElement to="/contratos" text="Contratos" />
        </ul>
      )
    case ROLES.RESPONSABLE:
      return (
        <ul>
          <NavElement to="/leads" text="Leads" badge={newLeads} />
          <NavElement to="/clientes" text="Clientes" badge={clientDemandInfo} />
          <NavElement to="/contratos" text="Contratos" />
        </ul>
      )
    default:
      return (
        <ul>
          <NavElement to="/leads" text="Leads" badge={newLeads} />
          <NavElement to="/clientes" text="Clientes" badge={clientDemandInfo} />
          <NavElement to="/contratos" text="Contratos" />
          <NavbarDropdown title="Informes">
            <NavElement to="/informes-clientes" text="Informes de Clientes" />
            <NavElement to="/informes-contratos" text="Informes de Contratos" />
            <NavElement to="/informes-comercial" text="Informes Comerciales" />
          </NavbarDropdown>
          <NavbarDropdown title="Empresa">
            <NavElement to="/usuarios" text="Usuarios" />
            <NavElement to="/tiendas" text="Tiendas" />
            <NavElement to="/servicios" text="Servicios" />
            <NavElement to="/tarifas" text="Tarifas" />
            <NavElement to="/promociones" text="Promociones" />
          </NavbarDropdown>
        </ul>
      )
  }
}

export function NavElement({ to, text, item, badge, cb }) {
  return (
    <li className="item" onClick={cb}>
      <NavLink to={to} className="nav-link" activeClassName="active-nav-item">
        <div className="d-flex align-items-center">
          <p className="text-link">{text}</p>

          {badge && badge.length ? (
            <BadgeLeads>{badge.length}</BadgeLeads>
          ) : null}
        </div>
      </NavLink>
    </li>
  )
}

function NavbarDropdown({ title, children }) {
  return (
    <li className="item">
      <a className="nav-link nav-drop">
        {title} <Icon path={mdiChevronDown} size={1} />
      </a>
      <ul className="submenu">
        <div className="submenu-container">{children}</div>
      </ul>
    </li>
  )
}
