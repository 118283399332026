import styled from 'styled-components'

export const CartStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.lila_claro};
  border-radius: 20px;
  position: sticky;
  top: 40px;

  .more-info {
    padding: 12px 24px;
    color: white;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.lila_muy_oscuro};
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    img {
      margin-right: 8px;
      margin-top: -1px;
    }
  }

  .pricing {
    padding: 24px;
    display: flex;
    align-items: center;

    .price {
      color: ${({ theme }) => theme.colors.amarillo_super_claro};
      font-size: 66px;
      font-family: ${({ theme }) => theme.fonts.montserrat};
      font-weight: 700;
      margin-right: 14px;
    }

    .euro {
      color: ${({ theme }) => theme.colors.amarillo_super_claro};
      font-size: 24px;
      font-family: ${({ theme }) => theme.fonts.montserrat};
      font-weight: 300;
      margin-bottom: 6px;
    }

    .iva {
      color: ${({ theme }) => theme.colors.rojo};
      font-family: ${({ theme }) => theme.fonts.montserrat};
      font-style: italic;
      font-size: 14px;
    }
  }

  .one_charge {
    align-items: center;
    color: yellow;
    display: flex;
    padding: 24px;
    padding-top: 0px;

    p {
      margin-left: 4px;
    }
  }

  .buttons {
    padding: 24px;

    button {
      display: block;
      width: 100%;
      padding: 20px;
      font-family: ${({ theme }) => theme.fonts.montserrat};
      font-style: italic;
      font-size: 24px;
      border-radius: 20px;
      font-weight: 700;
    }

    .contratar {
      background-color: ${({ theme }) => theme.colors.rojo};
      color: ${({ theme }) => theme.colors.amarillo_super_claro};
    }
  }

  .services {
    background-color: ${({ theme }) => theme.colors.lila};
    padding-top: 24px;
    padding-bottom: 1px;

    .extra-option {
      background-color: ${({ theme }) => theme.colors.lila_oscuro};
      margin-bottom: 14px;
      padding: 12px 16px;
      display: flex;

      .extra-option-item {
        padding: 8px 0;
      }
    }
  }

  .extras {
    background-color: ${({ theme }) => theme.colors.lila_claro};
    padding: 16px 24px 0px 24px;

    h6 {
      color: ${({ theme }) => theme.colors.rojo};
      font-size: 18px;
      font-style: italic;
      margin-bottom: 16px;
    }

    div {
      margin-bottom: 0px;
    }
  }
`

export const ServiceRowStyled = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 24px;
  padding: 0 24px;

  p {
    font-family: ${({ theme }) => theme.fonts.montserrat};
  }

  .service {
    display: flex;
    p {
      margin-left: 20px;
      color: white;
    }

    span {
      color: ${({ theme }) => theme.colors.amarillo};
      margin-left: 20px;
      font-style: italic;
      font-size: 14px;
    }
  }

  .price {
    font-weight: 600;
    color: white;
  }
`

export const OptionStyled = styled.button`
  width: 100%;
  padding: 20px;
  border-radius: 20px;
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.lila : '#e9dfff'};
  font-family: ${({ theme }) => theme.fonts.montserrat};
  font-size: 24px;
  font-weight: 600;
  color: ${({ theme, selected }) =>
    selected ? theme.colors.amarillo_super_claro : theme.colors.lila};
  transition: all 0.5s ease;
  opacity: ${(props) => (props.disabled ? 0.2 : 1)};

  &:hover {
    background-color: ${({ theme }) => theme.colors.lila};
    color: ${({ theme }) => theme.colors.amarillo_super_claro};
  }

  @media (max-width: 1000px) {
    margin-bottom: 12px;
    font-size: 18px;
  }
`

export const ServiceStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.blanco};
  margin-bottom: 32px;
  border-radius: 20px;

  h4 {
    color: ${({ theme }) => theme.colors.rojo};
    font-style: normal;
  }

  a {
    margin-left: 12px;
    font-weight: 600;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.amarillo_muy_oscuro};
    border-bottom: 2px solid ${({ theme }) => theme.colors.amarillo_muy_oscuro};
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(253 220 75 / 31%);
    padding-right: 16px;
    border-radius: 14px;
    div {
      padding: 10px 24px;
      display: flex;
      align-items: center;

      h2 {
        color: ${({ theme }) => theme.colors.lila};
        margin-left: 12px;
        font-size: 18px;
      }
    }
  }

  .body-services {
    padding: ${({ active }) => (active ? '24px' : '0')};

    .row {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));

      .services-config {
        margin: 5px;
      }
    }
  }
`

export const OptionRateStyled = styled.button`
  border: 3px solid
    ${({ theme, selected }) =>
      selected
        ? theme.colors.rojo
        : theme.colors.amarillo_super_claro} !important;
  width: 100%;
  padding: 12px;
  margin-bottom: 12px;
  border-radius: 20px;
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.rojo : 'transparent'};
  font-family: ${({ theme }) => theme.fonts.montserrat};
  font-size: 20px;
  font-weight: 600;
  color: ${({ theme, selected }) =>
    selected
      ? theme.colors.amarillo_super_claro
      : theme.colors.amarillo_super_claro};
  transition: all 0.5s ease;

  &:hover {
    border: 3px solid ${({ theme }) => theme.colors.rojo} !important;
    background-color: ${({ theme }) => theme.colors.rojo};
    color: ${({ theme }) => theme.colors.amarillo_super_claro};
  }
`

export const ServiceRateStyled = styled.div`
  h4 {
    color: ${({ theme }) => theme.colors.rojo};
    font-family: ${({ theme }) => theme.fonts.montserrat};
    font-style: normal;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${({ theme, isTv }) =>
      isTv ? theme.colors.amarillo_oscuro : theme.colors.amarillo};
    padding-right: 16px;
    div {
      background-color: ${({ theme, isTv }) =>
        isTv ? theme.colors.amarillo_oscuro : theme.colors.amarillo};
      width: 230px;
      padding: 10px 24px;
      display: flex;
      border-radius: ${({ active }) =>
        active ? '14px 0 0 0' : '14px 0 0 14px'};

      h2 {
        color: ${({ theme }) => theme.colors.lila};
        margin-left: 12px;
        font-size: 16px;
      }
    }
  }

  .body-services {
    ${'' /* padding: ${({ active }) => (active ? '24px' : '0')}; */}
    padding: ${({ active }) => (active ? '12px' : '0')};
  }
`

export const ButtonNewLine = styled.button`
  background-color: transparent;
  font-family: ${({ theme }) => theme.fonts.montserrat};
  color: ${({ theme, isRate }) =>
    isRate ? theme.colors.blanco : theme.colors.lila};
  margin-top: 20px;
  font-size: 18px;

  img {
    margin-right: 12px;
  }
`
// ESTILOS PARA CARD TARIFA
export const CartRateStyled = styled.div`
  .card-rate {
    background-color: ${({ theme }) => theme.colors.lila};
    border-radius: 32px;
    opacity: ${({ selected }) => (selected ? 1 : 0.3)};
    margin-bottom: 24px;
  }

  h1 {
    color: ${({ theme }) => theme.colors.lila};
    border-radius: 20px 20px 0 0;
    padding: 8px;
    margin: 0 42px;
    font-size: 16px;
    text-align: center;
    background-color: ${({ theme }) => theme.colors.azul};
  }

  .round-icon {
    background-color: #cdf9f6;
    width: 28px;
    height: 28px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
  }

  .card-button {
    border: 3px solid ${({ theme }) => theme.colors.azul};
    color: ${({ theme }) => theme.colors.lila};
    background-color: ${({ theme }) => theme.colors.azul};
    border-radius: 20px;
    padding: 18px 0;
    width: 100%;
    font-style: italic;
    font-weight: 600;
    font-size: 18px;
  }

  .card-button-selected {
    border: 3px solid ${({ theme }) => theme.colors.azul};
    color: ${({ theme }) => theme.colors.azul};
    background-color: ${({ theme }) => theme.colors.lila};
    border-radius: 20px;
    padding: 18px 0;
    width: 100%;
    font-style: italic;
    font-weight: 600;
    font-size: 18px;
  }
  .content {
    padding: 24px;
  }

  .service-item {
    margin-bottom: 20px;
    p {
      color: ${({ theme }) => theme.colors.azul};
      font-weight: 500;
      text-align: center;
      font-size: 22px;
    }

    span {
      color: ${({ theme }) => theme.colors.blanco};
      text-align: center;
      display: block;
      font-size: 24px;
      font-weight: 600;
    }
  }

  .pricing {
    display: flex;
    justify-content: center;
    border-top: 1px solid white;

    .price {
      color: ${({ theme }) => theme.colors.blanco};
      font-size: 82px;
      font-weight: 800;
      margin-right: 6px;
      font-style: italic;
    }

    .euro {
      color: ${({ theme }) => theme.colors.blanco};
      font-size: 44px;
      font-weight: 400;
      padding-top: 8px;

      span {
        display: block;
        font-size: 14px;
      }
    }
  }
`

export const ButtonExtraRate = styled.button`
  background-color: transparent;
  color: white;
  display: block;
  margin: 12px 24px 0px;
  font-family: ${({ theme }) => theme.fonts.montserrat};
  transition: color 0.5s ease;

  img {
    margin-right: 12px;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.rojo};
  }
`

export const CardServiceStyled = styled.div`
  border-radius: 20px;
  margin-top: 24px;

  box-shadow: 0px 0px 30px rgb(192 185 198 / 30%);
  background-color: white;

  .title-service {
    padding: 24px;
    cursor: pointer;

    h3 {
      color: ${({ theme }) => theme.colors.gris_oscuro};
      margin-left: 8px;
      font-size: 18px;

      span {
        font-weight: 400;
        font-size: 16px;
      }
    }
  }

  #collapse-service {
    padding: 0px 24px 24px 24px;
    margin-top: -20px;
  }

  .spans {
    span {
      margin-right: 12px;
    }
  }

  .resume {
    padding: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    span {
      margin-right: 16px;
    }
  }

  .more-info {
    padding: 0 32px 32px 32px;
    position: relative;

    .icon-edit {
      position: absolute;
      top: 0;
      right: 2rem;
    }
  }

  h2 {
    color: ${({ theme }) => theme.colors.azul};
    font-size: 22px;
    margin-left: 24px;
    font-family: ${({ theme }) => theme.fonts.montserrat} !important;
  }

  .flex {
    display: flex;
    align-items: center;

    .badges {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;

      .details {
        margin-bottom: 5px;
      }
    }
  }

  .contract-button {
    font-size: 14px;
    padding: 6px 14px;
    border-radius: 8px;
    margin-left: 10px;
    background-color: ${({ theme }) => theme.colors.amarillo_super_claro};
    color: ${({ theme }) => theme.colors.amarillo_muy_oscuro};
  }
`

export const TableResumeStyle = styled.table`
  .right {
    text-align: right;
  }

  th {
    background-color: ${({ theme }) => theme.colors.amarillo_super_claro};
    border-bottom: none !important;
    color: ${({ theme }) => theme.colors.lila} !important;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 14px;
  }

  .title {
    display: flex;
    align-items: center;

    h5 {
      margin-left: 22px;
    }
  }

  td {
    padding: 22px 0 !important;
  }

  tbody {
    tr:first-child {
      td {
        border-top: none !important;
      }
    }
  }
`

export const ImageStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  img {
    width: 140px;
    margin: -112px 30px 0 0;
  }

  @media (max-width: 1000px) {
    img {
      margin-top: -30px;
      width: 100px;
    }
  }
`
export const UpdateContractStyles = styled.div`
  color: ${({ theme }) => theme.colors.gris_muy_oscuro};
  margin-top: 42px;

  .hola {
    font-weight: 600;
    margin-bottom: 8px;
  }

  .resumen-tarifa {
    color: ${({ theme }) => theme.colors.lila};
    margin: 32px 0;
    font-size: 22px;
  }

  .cambios-realizados {
    background-color: ${({ theme }) => theme.colors.gris_muy_claro};
    padding: 24px;
    margin-top: 24px;

    .resumen-servicio {
      color: ${({ theme }) => theme.colors.lila};
      font-weight: 600;
      background: ${({ theme }) => theme.colors.gris_claro};
      display: inline-block;
      padding: 6px 12px;
    }

    li {
      line-height: 1.5em;
      display: flex;
      align-items: flex-start;
      margin-bottom: 12px;

      p {
        max-width: 80%;
      }

      svg {
        width: 20px;
        margin: 1px 8px 0 0;
      }
    }

    a {
      color: ${({ theme }) => theme.colors.lila};
      font-weight: 600;
      border-bottom: 1px solid ${({ theme }) => theme.colors.lila};
    }

    h2 {
      margin-bottom: 24px;
    }
  }
`

export const FormContractStyled = styled.div`
  .title-service {
    color: ${({ theme }) => theme.colors.lila};
    font-size: 24px;
    margin-bottom: 24px;
    font-style: italic;
  }

  .block-button {
    background-color: #e1fffd;
    padding: ${({ isAddNew }) => (isAddNew ? '24px 12px' : '48px 24px')};
    border-radius: 24px;
    margin-bottom: 24px;
    text-align: center;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.azul};
    cursor: pointer;
    transition: background-color 0.5s ease;
    font-style: italic;
    font-size: ${({ isAddNew }) => (isAddNew ? '16px' : '20px')};

    &:hover {
      background-color: #c2fffb;
    }

    label {
      color: ${({ theme }) => theme.colors.azul};
      margin: 0;
      text-align: left;
    }

    .checkmark {
      background-color: #a3f1ec;
    }
  }

  .active {
    background-color: ${({ theme }) => theme.colors.azul};
    color: white;

    &:hover {
      color: ${({ theme }) => theme.colors.azul};
    }
  }

  .check-direccion {
    padding: 24px;
  }
`

export const StepperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  .step {
    color: ${({ theme }) => theme.colors.gris};
    font-size: 12px;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100px;
    text-align: center;

    .number {
      background-color: ${({ theme }) => theme.colors.gris};
      color: white;
      width: 32px;
      height: 32px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 12px;
      opacity: 0.6;
    }
  }

  .active {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.verde};

    .number {
      background-color: ${({ theme }) => theme.colors.verde};
      opacity: 1;
    }
  }
`
