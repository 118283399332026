import { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import FallbackLoader from 'components/Common/FallbackLoader'
import ProtectedRoute from './components/ProtectedRoute'
import { ADMIN, RESPONSABLE } from 'utils/constants'
import LeadsDistribuidores from 'pages/LeadsDistribuidores'

export default function Routes() {
  const LazyHome = lazy(() => import('pages/Inicio'))
  const LazyLeads = lazy(() => import('pages/Leads'))
  const LazyAyuda = lazy(() => import('pages/Ayuda'))
  const LazyManual = lazy(() => import('pages/Manual'))

  const LazyClienteLead = lazy(() => import('pages/ClienteLead'))
  const LazyClientes = lazy(() => import('pages/Clientes'))
  const LazyClienteDetalles = lazy(() => import('pages/ClienteDetalles'))
  const LazyClienteAction = lazy(() => import('pages/ClienteAction'))
  const LazyDatosClientes = lazy(() => import('pages/DatosClientes'))

  const LazyContratos = lazy(() => import('pages/Contratos'))
  const LazyContratoNuevo = lazy(() => import('pages/ContratoNuevo'))
  const LazyContratoEdit = lazy(() => import('pages/ContratoEdit'))
  const LazyContratoEditPromociones = lazy(() =>
    import('pages/ContratoEditPromociones')
  )
  const LazyContratar = lazy(() => import('pages/Contratar'))

  const LazyInformesClientes = lazy(() => import('pages/InformesClientes'))
  const LazyInformesContratos = lazy(() => import('pages/InformesContratos'))
  const LazyInformesComercial = lazy(() => import('pages/InformesComercial'))

  const LazyServicios = lazy(() => import('pages/Servicios'))
  const LazyServicioDetalles = lazy(() => import('pages/ServicioDetalles'))

  const LazyTarifas = lazy(() => import('pages/Tarifas'))
  const LazyTarifaDetalles = lazy(() => import('pages/TarifaDetalles'))
  const LazyTarifaAction = lazy(() => import('pages/TarifaAction'))

  const LazyTiendas = lazy(() => import('pages/Tiendas'))
  const LazyTiendaDetalles = lazy(() => import('pages/TiendaDetalles'))
  const LazyTiendaAction = lazy(() => import('pages/TiendaAction'))

  const LazyUsuarios = lazy(() => import('pages/Usuarios'))
  const LazyUsuarioDetalles = lazy(() => import('pages/UsuarioDetalles'))
  const LazyUsuarioAction = lazy(() => import('pages/UsuarioAction'))

  const LazyPromos = lazy(() => import('pages/Promociones'))
  const LazyPromosAction = lazy(() => import('pages/PromocionesAction'))

  const LazyConfiguracion = lazy(() => import('pages/Configuracion'))
  const LazyPerfil = lazy(() => import('pages/ConfiguracionUsuario'))
  const LazyCobertura = lazy(() => import('pages/Cobertura'))
  const LazyTesting = lazy(() => import('pages/Testing'))
  const LazyError = lazy(() => import('pages/ErrorPage'))

  return (
    <Suspense fallback={<FallbackLoader />}>
      <Switch>
        <Route exact path="/" component={LazyHome} />
        <Route exact path="/ayuda" component={LazyAyuda} />
        <Route exact path="/manual" component={LazyManual} />
        {/* Client Routes */}
        <Route
          exact
          path="/leadsDistribuidores"
          component={LeadsDistribuidores}
        />
        <Route exact path="/leads" component={LazyLeads} />
        <Route exact path="/leads/nuevo" component={LazyClienteLead} />
        <Route exact path="/clientes" component={LazyClientes} />
        <Route exact path="/clientes-datos" component={LazyDatosClientes} />
        <Route exact path="/clientes/nuevo" component={LazyClienteAction} />
        <Route path="/clientes/edit/:clientId" component={LazyClienteAction} />
        <Route path="/clientes/:clientId" component={LazyClienteDetalles} />
        {/* Contract Routes */}
        <Route exact path="/contratos" component={LazyContratos} />
        <Route exact path="/contratar" component={LazyContratar} />
        <Route exact path="/contratos/nuevo" component={LazyContratoNuevo} />
        <Route
          path="/contratos/edit/promociones/:contractId"
          component={LazyContratoEditPromociones}
          exact
        />
        <Route
          path="/contratos/edit/:contractId"
          component={LazyContratoEdit}
        />
        {/* Service Routes */}
        <ProtectedRoute exact path="/servicios" Component={LazyServicios} />
        <ProtectedRoute
          path="/servicios/:service"
          Component={LazyServicioDetalles}
        />
        {/* Rate Routes */}
        <ProtectedRoute exact path="/tarifas" Component={LazyTarifas} />
        <ProtectedRoute
          exact
          path="/tarifas/nueva"
          Component={LazyTarifaAction}
        />
        <ProtectedRoute
          path="/tarifas/edit/:rateId"
          Component={LazyTarifaAction}
        />
        <ProtectedRoute
          path="/tarifas/:rateId"
          Component={LazyTarifaDetalles}
        />
        {/* Shop Routes */}
        <ProtectedRoute exact path="/tiendas" Component={LazyTiendas} />
        <ProtectedRoute
          exact
          path="/tiendas/nueva"
          Component={LazyTiendaAction}
        />
        <ProtectedRoute
          path="/tiendas/edit/:shopId"
          Component={LazyTiendaAction}
        />
        <ProtectedRoute
          path="/tiendas/:shopId"
          Component={LazyTiendaDetalles}
        />
        {/* User Routes */}
        <ProtectedRoute exact path="/usuarios" Component={LazyUsuarios} />
        <ProtectedRoute
          exact
          path="/usuarios/nuevo"
          Component={LazyUsuarioAction}
        />
        <ProtectedRoute
          path="/usuarios/edit/:userDni"
          Component={LazyUsuarioAction}
        />
        <ProtectedRoute
          path="/usuarios/:userDni"
          Component={LazyUsuarioDetalles}
        />
        {/* Rest */}
        <ProtectedRoute path="/configuracion" Component={LazyConfiguracion} />

        <ProtectedRoute
          exact
          path="/informes-clientes"
          authorizedRoles={[RESPONSABLE, ADMIN]}
          Component={LazyInformesClientes}
        />
        <ProtectedRoute
          exact
          path="/informes-contratos"
          authorizedRoles={[RESPONSABLE, ADMIN]}
          Component={LazyInformesContratos}
        />
        <ProtectedRoute
          exact
          path="/informes-comercial"
          authorizedRoles={[RESPONSABLE, ADMIN]}
          Component={LazyInformesComercial}
        />
        <Route exact path="/perfil" component={LazyPerfil} />
        <Route exact path="/promociones" component={LazyPromos} />
        <Route exact path="/promociones/nueva" component={LazyPromosAction} />
        <Route
          exact
          path="/promociones/editar/:id"
          component={LazyPromosAction}
        />
        {/* <ProtectedRoute path="/pdf" component={P.PreviewPDF} /> */}
        <Route exact path="/cobertura" component={LazyCobertura} />
        {process.env.NODE_ENV === 'development' && (
          <Route exact path="/testing" component={LazyTesting} />
        )}
        <ProtectedRoute path="*" Component={LazyError} />
      </Switch>
    </Suspense>
  )
}
