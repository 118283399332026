import {
  ADMIN,
  AGENT_MEET,
  CANCELLED_PORTALIBILITY,
  COMPANY,
  EXT_AD,
  FRIENDS,
  INTERNET,
  LANDLINE_PHONE,
  LAUNCHED_PORTABILITY,
  MOBILE,
  OTHER_MEET,
  PENDING,
  PENDING_INSTALLATION,
  PRINT_AD,
  PRIVATE,
  PROVISIONED,
  PROVISION_PENDING,
  RESPONSABLE,
  SELF_EMPLOYED,
  SHOP_MEET,
  SOCIAL,
  TV,
  USER
} from './constants'

export function capitalize(str) {
  if (!str) return ''
  return str
    .split(' ')
    .map((subStr) => {
      const normalizedString = subStr.toLowerCase()
      return normalizedString.replace(
        normalizedString.charAt(0),
        normalizedString.charAt(0).toUpperCase()
      )
    })
    .join(' ')
}

export function translatePath(pathName) {
  if (!pathName) return ''
  const lowerName = pathName.toLowerCase()
  switch (lowerName) {
    case 'shop':
      return 'Tienda'
    case 'call':
      return 'Llamada'
    case 'agent':
      return 'Comercial'
    case 'client':
      return 'Cliente'
    case 'contract':
      return 'Contrato'
    case 'contract/update':
      return 'Contrato'
    case 'contractedservice':
      return 'Servicio'
    case 'service':
      return 'Servicio'
    case 'rate':
      return 'Tarifa'
    case 'user':
      return 'Usuario'
    case PENDING.toLowerCase():
      return 'Firma pendiente'
    case 'pending installation':
      return 'Alta pendiente'
    case 'signed':
      return 'Firmado'
    case 'valid':
      return 'Activo'
    case 'cancelled':
      return 'Cancelado'
    case TV.toLowerCase():
      return 'Televisión'
    case INTERNET.toLowerCase():
      return 'Fibra'
    case MOBILE.toLowerCase():
      return 'Móvil'
    case LANDLINE_PHONE.toLowerCase():
      return 'Teléfono Fijo'
    case ADMIN:
      return 'Administrador'
    case RESPONSABLE:
      return 'Responsable'
    case USER:
      return 'Usuario'
    case PRIVATE.toLowerCase():
      return 'Particular'
    case SELF_EMPLOYED.toLowerCase():
      return 'Autónomo'
    case COMPANY.toLowerCase():
      return 'Empresa'
    case FRIENDS:
      return 'Familiar/amigo'
    case SOCIAL:
      return 'Redes sociales'
    case EXT_AD:
      return 'Publicidad exteriores'
    case PRINT_AD:
      return 'Publicidad impresa'
    case SHOP_MEET:
      return 'Tienda'
    case AGENT_MEET:
      return 'Comercial'
    case OTHER_MEET:
      return 'Otros'
    case PROVISION_PENDING.toLowerCase():
      return 'Provisión pendiente'
    case PENDING_INSTALLATION.toLowerCase():
      return 'Pendiente instalación'
    case LAUNCHED_PORTABILITY.toLowerCase():
      return 'Portabilidad lanzada'
    case CANCELLED_PORTALIBILITY.toLowerCase():
      return 'Portabilidad cancelada'
    case PROVISIONED.toLowerCase():
      return 'Activo'
    default:
      return pathName
  }
}

export function isEditRoute(match) {
  return match.path.includes('edit')
}

export function getFullName(client) {
  if (!client) return '-'
  const fullName =
    client.clientType !== COMPANY
      ? `${client.name} ${client.lastname1 || ''} ${client.lastname2 || ''}`
      : `${client.name}`
  return fullName.trim()
}

export function getFullAddress(element) {
  if (!element?.address) return ''
  const { address } = element
  const fullAddress = `${address.street}, ${address.number || '-'} ${
    address.block || ''
  } ${address.level ? `${address.level}-${address.door}` : address.level || ''}`
  return fullAddress.trim()
}

export function padStartString(string, padLimit, padChar, sliceChars = 0) {
  if (!string) return ''
  return string.trim().slice(sliceChars).padStart(padLimit, padChar)
}
