import { getFullName } from 'utils/strings'
import { CALL_AGAIN } from 'utils/constants'

export function callAgainComments(clients) {
  return clients
    .reduce((acc, client) => {
      client.comments.forEach((c) => {
        if (c.type === CALL_AGAIN && !c.isSolved) {
          acc.push({
            ...c,
            client: {
              name: getFullName(client),
              _id: client._id,
              tag: client.tag,
              phoneNumber: client.phoneNumber
            },
            contactDate: new Date(c.contactDate)
          })
        }
      })
      return acc
    }, [])
    .sort((c1, c2) => c1.contactDate - c2.contactDate)
}

export function sortClientByComments(client1, client2) {
  const comments1 = client1.comments?.length || 0
  const comments2 = client2.comments?.length || 0
  if (comments1 !== 0 && comments2 !== 0)
    return (
      new Date(client2.comments[client2.comments.length - 1].createdAt) -
      new Date(client1.comments[client1.comments.length - 1].createdAt)
    )
  return comments1 - comments2
}

export function lastComment(comment) {
  if (!comment) return ''

  const date = new Date(comment.createdAt)
  const hour = date.toLocaleTimeString('es-ES', {
    hour: '2-digit',
    minute: '2-digit'
  })
  const day = date.toLocaleDateString('es-ES')

  return (
    <div className="last-comment">
      <p className="comment">{comment.comment}</p>
      <p className="date">
        {day} - {hour}
      </p>
    </div>
  )
}

export function getBadgeProps(origin) {
  const badgeHashMap = {
    web: { text: 'Web', color: 'green' },
    whatsapp: { text: 'WhatsApp', color: 'blue' },
    call: { text: 'Llamada', color: 'yellow' },
    shop: { text: 'Tienda', color: 'purple' },
    agent: { text: 'Comercial', color: 'dark-yellow' },
    distribuidor: { text: 'Distribuidor', color: 'dark-yellow' }
  }
  return badgeHashMap[origin]
}
