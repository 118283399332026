export default function LogoFull({ width, color }) {
  return (
    <svg
      width={width}
      viewBox="0 0 68 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M58.9592 10.0332H55.0451V6.93987C55.0451 5.53426 55.7865 5.27125 56.886 5.27125C57.1572 5.27125 57.598 5.31336 58.0514 5.36104C58.6234 5.42143 59.1223 4.97328 59.1223 4.39801V1.14976C59.1223 0.640432 58.734 0.216127 58.2257 0.172425C57.3299 0.0961454 55.9743 0 55.0427 0C51.9647 0 49.3362 1.36112 49.3362 5.31416V10.0316H47.5183C46.9805 10.0316 46.5398 10.471 46.5398 11.0089V13.9417C46.5398 14.4812 46.9781 14.9191 47.5183 14.9191H49.3362V29.417C49.3362 29.9565 49.7745 30.3943 50.3147 30.3943H54.0665C54.6067 30.3943 55.0451 29.9565 55.0451 29.417V14.9198H58.9592C59.4994 14.9198 59.9377 14.482 59.9377 13.9425V11.0105C59.9377 10.471 59.4994 10.0332 58.9592 10.0332Z"
        fill={color}
      />
      <path
        d="M11.6286 9.58501C8.59436 9.58501 6.4042 10.9906 5.43681 12.703C5.43681 12.1324 5.70889 10.7268 5.70889 9.40862V1.33488C5.70889 0.795358 5.27054 0.357544 4.73036 0.357544H0.978529C0.438349 0.357544 0 0.795358 0 1.33488V29.4178C0 29.9573 0.438349 30.3951 0.978529 30.3951H4.73036C5.26815 30.3951 5.70889 29.9549 5.70889 29.4178V27.68C6.52433 29.3931 8.91099 30.7542 11.7686 30.7542C17.3096 30.7542 21.4831 26.3181 21.4831 20.1696C21.4831 14.0204 17.1688 9.58501 11.6286 9.58501ZM10.3533 25.7039C7.31909 25.7039 5.20849 23.2439 5.20849 20.1696C5.20849 17.0517 7.31909 14.6799 10.3533 14.6799C13.3876 14.6799 15.4982 17.0517 15.4982 20.1696C15.4982 23.2439 13.3876 25.7039 10.3533 25.7039Z"
        fill={color}
      />
      <path
        d="M66.9857 10.0332H63.2346C62.6944 10.0332 62.2561 10.471 62.2561 11.0105V29.4178C62.2561 29.9573 62.6944 30.3951 63.2346 30.3951H66.9865C67.5267 30.3951 67.965 29.9573 67.965 29.4178V11.0105C67.9642 10.471 67.5259 10.0332 66.9857 10.0332Z"
        fill={color}
      />
      <path
        d="M34.3551 13.79C36.4219 13.79 38.0488 12.2088 38.0488 10.1444C38.0488 8.12461 36.4211 6.4552 34.3551 6.4552C32.332 6.4552 30.7051 8.12382 30.7051 10.1444C30.7051 12.2088 32.332 13.79 34.3551 13.79Z"
        fill={color}
      />
      <path
        d="M43.5431 14.9198H39.792C39.2519 14.9198 38.8135 15.3576 38.8135 15.8971V20.6749C38.8135 23.1223 36.827 25.1055 34.3775 25.1055C31.928 25.1055 29.9415 23.1215 29.9415 20.6749V15.8971C29.9415 15.3576 29.5031 14.9198 28.963 14.9198H25.2119C24.6717 14.9198 24.2334 15.3576 24.2334 15.8971V20.6749C24.2334 25.2803 27.3114 29.1658 31.5238 30.3974V39.0226C31.5238 39.5621 31.9622 40 32.5024 40H36.2542C36.7944 40 37.2327 39.5621 37.2327 39.0226V30.3982C41.4452 29.1666 44.5232 25.2811 44.5232 20.6757V15.8979C44.5216 15.3576 44.0832 14.9198 43.5431 14.9198Z"
        fill={color}
      />
    </svg>
  )
}
