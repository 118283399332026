import { NavLink } from 'react-router-dom'
import { NavbarMenuMobileStyled } from './NavbarStyle'
import {
  mdiAccountStarOutline,
  mdiCardAccountDetailsOutline,
  mdiFileDocumentOutline
} from '@mdi/js'
import Icon from '@mdi/react'

export default function NavbarMenuMobile() {
  return (
    <NavbarMenuMobileStyled>
      <NavElementMobile to="/leads" text="Leads" icon={mdiAccountStarOutline} />
      <NavElementMobile
        to="/clientes"
        text="Clientes"
        icon={mdiCardAccountDetailsOutline}
      />
      <NavElementMobile
        to="/contratos"
        text="Contratos"
        icon={mdiFileDocumentOutline}
      />
    </NavbarMenuMobileStyled>
  )
}

function NavElementMobile({ to, text, icon }) {
  return (
    <li className="item">
      <NavLink to={to} className="nav-link" activeClassName="active-nav-item">
        <Icon path={icon} size={0.8} />
        {text}
      </NavLink>
    </li>
  )
}
