import { INTERNET, MOBILE } from './constants'

export function euroFormat(numero) {
  return Intl.NumberFormat('es-ES', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(numero)
}

function hasInternet({ rate = null, services = [] }) {
  // s.service se comprueba porque tanto rate.services como services pueden venir con o sin datos de portabilidad
  const hasInternetFromServices = services.find((s) =>
    s.service ? s.service.type === INTERNET : s.type === INTERNET
  )
  const hasInternetFromRate = rate?.services.find((s) =>
    s.service ? s.service.type === INTERNET : s.type === INTERNET
  )
  return hasInternetFromServices || hasInternetFromRate
}

export function servicePrices({
  rate = null,
  services = [],
  extras = [],
  extraOptionsArr = []
}) {
  const basePrice = rate
    ? rate.price || cardPrice(rate)
    : services.reduce((acc, s) => acc + s.normalPrice, 0)

  const extraPrice = extras.reduce(
    (acc, s) =>
      s.type === MOBILE
        ? hasInternet({ rate, services })
          ? acc + s.extraPrice
          : acc + s.normalPrice
        : acc + s.extraPrice,
    0
  )

  const allDataServices = rate
    ? [...rate.services, ...extras]
    : [...services, ...extras]

  const extraOptionsPrice = extraOptionsArr.length
    ? extraOptionsArr.reduce(
        (acc, e) => (e.oneChargeOnly ? acc : acc + e.price),
        0
      )
    : allDataServices.reduce(
        (acc, s) =>
          s.extraOption
            ? acc +
              s.extraOption.reduce(
                (accE, e) => (e.oneChargeOnly ? accE : accE + e.price),
                0
              )
            : acc,
        0
      )

  const oneChargePrice = extraOptionsArr.length
    ? 0
    : allDataServices.reduce(
        (acc, s) =>
          s.extraOption
            ? acc +
              s.extraOption.reduce(
                (accE, e) => (e.oneChargeOnly ? accE + e.price : accE),
                0
              )
            : acc,
        0
      )

  const totalPrice = basePrice + extraPrice + extraOptionsPrice

  return { totalPrice, basePrice, extraPrice, oneChargePrice }
}

//Aqui está la lógica para añadir promos
export function monthlyPrice({
  rate = null,
  services = [],
  extras = [],
  promotions = []
}) {
  const formatedServices = {
    services: services.map(({ service }) => service.normalPrice),
    extras: extras.map(({ service }) =>
      hasInternet({ rate, services }) ? service.extraPrice : service.normalPrice
    ),
    promotions: promotions.map((promotion) => {
      return { value: promotion.discount, type: promotion.discountType }
    })
  }

  const formatedRate = rate && {
    ...rate,
    services: [...rate.services.map(({ service }) => service)]
  }
  const basePrice = rate
    ? rate.price || cardPrice(formatedRate)
    : formatedServices.services.reduce((acc, v) => acc + v, 0)

  const extraPrice = formatedServices.extras.reduce((acc, v) => acc + v, 0)

  const allDataServices = rate
    ? [...rate.services, ...extras]
    : [...services, ...extras]

  const extraOptionsPrice = allDataServices.reduce(
    (acc, s) =>
      s.extraOption
        ? acc +
          s.extraOption.reduce(
            (accE, e) => (e.oneChargeOnly ? accE : accE + e.price),
            0
          )
        : acc,
    0
  )

  const monthlyCost = basePrice + extraPrice + extraOptionsPrice

  if (formatedServices.promotions[0]?.type === 'percentage') {
    return monthlyCost * (formatedServices.promotions[0].value / 100)
  } else if (formatedServices.promotions[0]?.type === 'amount') {
    return monthlyCost - formatedServices.promotions[0].value
  } else {
    return monthlyCost
  }
}

export function oneChargeExtraCost(extras) {
  return extras.reduce((acc, e) => (e.oneChargeOnly ? acc + e.price : acc), 0)
}

export function cardPrice({ services }) {
  const mobilePrice = services
    .filter(({ type }) => type === MOBILE)
    .reduce((acc, s, i) => (!i ? acc + s.normalPrice : acc + s.extraPrice), 0)
  const restPrice = services
    .filter(({ type }) => type !== MOBILE)
    .reduce((acc, s) => acc + s.normalPrice, 0)
  return mobilePrice + restPrice
}

export function getPriceFromContract(contract) {
  if (contract.totalAmount && contract.totalAmount > 0)
    return contract.totalAmount
  const { contractedServices } = contract

  const extraOptionsArr = contractedServices.reduce(
    (acc, s) => (s.extraOption?.length ? [...acc, ...s.extraOption] : acc),
    []
  )

  const baseServices = contractedServices
    .filter((service) => !service.extra)
    .map(({ service }) => service)
  const hasExtras = contractedServices
    .filter((service) => service.extra)
    .map(({ service }) => service)

  const { totalPrice } = servicePrices({
    rate: contract.rate,
    services: contract.rate ? [] : baseServices,
    extras: hasExtras,
    extraOptionsArr
  })

  return totalPrice
}
