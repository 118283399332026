import { ToastContainer } from 'react-toastify'

import useAuth from 'hooks/useAuth'
import DataContextProvider from 'context/DataContext'

// Components
import NavbarMenuMobile from 'components/Navbar/NavbarMenuMobile'
import { LoaderByfi } from 'styles-byfi'
import UserNavbar from 'components/Navbar/UserNavbar'
import Login from 'components/Auth/Login'
import Routes from 'routes'

// Styles
import 'react-toastify/dist/ReactToastify.css'
import { useLocation } from 'react-router'
import UpdateContract from 'pages/UpdateContract'

export default function App() {
  const { auth, logout, loading } = useAuth()
  const location = useLocation()

  const isUpdateContract = location.pathname.includes('actualizacion-contrato')
  const isDashboard = location.pathname === '/'
  const isClient = location.pathname.includes('/clientes')

  if (isUpdateContract) {
    return <UpdateContract />
  }

  if (loading) return <LoaderByfi />
  if (auth === null) return <Login />

  return (
    <DataContextProvider>
      <UserNavbar {...{ auth, logout }} />

      <div className={isDashboard || isClient ? '' : 'container my-5'}>
        <Routes />
      </div>
      <NavbarMenuMobile />

      <ToastContainer autoClose={2000} />
    </DataContextProvider>
  )
}
