import { createContext, useState, useEffect, useMemo, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import useAuth from '../hooks/useAuth'
import { BASE_PATH } from '../utils/constants'

export const DataContext = createContext({})

export default function DataContextProvider({ children }) {
  const { location } = useHistory()
  const { auth } = useAuth()

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(initialState())
  const [refresh, setRefresh] = useState(false)

  const getAllData = useCallback(async () => {
    try {
      const {
        company,
        contractedServices,
        roles,
        landlineNumbers,
        altas,
        promotions
      } = await fetchData(auth.token, '/company/allData')

      setData({
        ...company,
        contractedServices,
        roles,
        landlineNumbers,
        altas,
        promotions
      })
      setLoading(false)
      setRefresh(false)
    } catch (error) {
      console.error(error)
    }
  }, [])

  const getStartData = useCallback(async () => {
    try {
      if (location.pathname !== '/') return getAllData()

      const { company, roles } = await fetchData(auth.token, '/company')

      setData({ ...company, roles })
      setLoading(false)
      setRefresh(true)
    } catch (error) {
      console.error(error)
    }
  }, [])

  useEffect(() => {
    getStartData()
  }, [])

  useEffect(() => {
    if (refresh) getAllData()
  }, [refresh])

  const refreshData = () => setRefresh(true)

  const updateData = (identifier, newData) => {
    setData({ ...data, [identifier]: newData })
  }

  const globalData = useMemo(
    () => ({
      loading,
      data,
      updateData,
      refreshData
    }),
    [loading, data]
  )

  return (
    <DataContext.Provider value={globalData}>{children}</DataContext.Provider>
  )
}

async function fetchData(token, url) {
  try {
    const path = `${BASE_PATH}${url}`

    const response = await fetch(path, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      }
    })
    const data = await response.json()

    return data
  } catch (error) {
    console.error(error)
    return null
  }
}

function initialState() {
  return {
    active: true,
    cif: '',
    clients: [],
    contracts: [],
    createdAt: '',
    landlineNumbers: [],
    logo: '',
    name: '',
    owner: '',
    rates: [],
    services: [],
    shops: [],
    updatedAt: '',
    updatedBy: '',
    users: [],
    _id: ''
  }
}
