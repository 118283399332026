import { MOBILE, INTERNET, TV } from 'utils/constants'
export default function RemoveTemplate({ type }) {
  const { service, returnMessage } = getMessages(type)

  return (
    <div>
      <p>
        Sentimos mucho que no hayas quedado satisfecho con {service}. Cualquier
        sugerencia, queja o recomendación siempre es bienvenida para mejorar
        nuestros servicios.
      </p>
      {returnMessage && (
        <p>
          Puedes pasar a devolver el {returnMessage} por cualquiera de nuestras
          tiendas Byfi.
        </p>
      )}
    </div>
  )
}

function getMessages(type) {
  switch (type) {
    case MOBILE:
      return { service: 'el móvil de Byfi', returnMessage: null }
    case INTERNET:
      return { service: 'la fibra de Byfi', returnMessage: 'router' }
    case TV:
      return { service: 'ByfiTv', returnMessage: 'decodificador' }
  }
}
